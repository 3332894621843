import React, { Component } from "react";
import { Dimensions, Platform } from "react-native";
import {
    Image,
    Text,
    View,
    Button,
    StyleSheet,
    TouchableOpacity,
    AsyncStorage,
    StatusBar,
    ImageBackground,
} from "react-native";
import styled from "styled-components";
import Svg, { Path, Circle } from "react-native-svg";
import * as Font from "expo-font";
import * as WebBrowser from 'expo-web-browser';

class Start extends Component {
    state = {
        fontLoaded: false,
    };
    async componentDidMount() {
        await Font.loadAsync({
            "cerebri-sans-semibold": require("../assets/fonts/Poppins-SemiBold.ttf"),
        });

        await Font.loadAsync({
            "cerebri-sans-regular": require("../assets/fonts/Poppins-Regular.ttf"),
        });

        await Font.loadAsync({
            "cerebri-sans-bold": require("../assets/fonts/Poppins-Bold.ttf"),
        });

        if (Platform.OS === 'web') {
            this.props.navigation.navigate("IndexEspecialidades");
        }

        this.setState({ fontLoaded: true });
    }
    render() {
        var heightScreen = Dimensions.get("window").height;
        return (
            <View>
                <StatusBar barStyle="light-content" backgroundColor="#FFAD37" />
                {this.state.fontLoaded ? (
                    <>
                        <View>
                            <ImageBackground
                                style={styles.mainContainer}
                            >   
                                <Image
                                 style={styles.doctorImg}
                                 resizeMode={'contain'}
                                 source={require("../assets/direct_uni_login.png")}
                                />
                                <View style={styles.startIntro}>

                                    <Text style={styles.startTitle}>
                                    Prepárate{"\n"}
                                    para el examen{"\n"}
                                    de ingreso a la{"\n"}
                                    Universidad
         </Text>
                                    <Text style={styles.startSubtitle}>
                                    Simula el examen para ingresar a la Universidad en el Área de la Salud
         </Text>
                                </View>

                                <View style={styles.containerForm}>
                                    <TouchableOpacity
                                        style={styles.buttonMain}
                                        onPress={() => this.props.navigation.navigate("Login")}
                                    >
                                        <Text style={styles.buttonText}>Iniciar Sesión</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={styles.buttonSecondary}
                                        // onPress={() => this.props.navigation.navigate("Signup")}
                                        onPress={() => { 
                                            WebBrowser.openBrowserAsync('https://registro.directuni.mx/');
                                        }}

                                    >
                                        <Text style={styles.buttonTextAlt}>Crear Cuenta</Text>
                                    </TouchableOpacity>
                                </View>
                            </ImageBackground>
                        </View>
                    </>
                ) : null}
            </View>
        );
    }
}

var heightHalf = Dimensions.get("window").height / 3;
var heightTitle = Dimensions.get("window").height / 24;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
    },
    backgroundImage: {
        flex: 1,
        resizeMode: "cover",
    },
    mainContainer: {
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: "#004A8F",
        width: "100%",
        height: "100%"
    },
    logoContainer: {
        alignItems: "center",
        flexGrow: 1,
        justifyContent: "flex-start",
        height: 540,
    },
    containerForm: {
        padding: 20,
    },
    buttonLogin: {
        backgroundColor: "red",
        padding: 10,
        marginBottom: 10,
    },
    buttonText: {
        textAlign: "center",
        color: "black",
        fontWeight: "700",
        fontSize: 14,
        fontFamily: "cerebri-sans-semibold",
    },
    buttonTextAlt: {
        textAlign: "center",
        color: "white",
        fontWeight: "700",
        fontSize: 14,
        fontFamily: "cerebri-sans-semibold",
    },
    buttonCreate: {
        backgroundColor: "black",
        padding: 10,
        marginBottom: 10,
    },
    loginIntroLogo: {
        marginBottom: 40,
        marginTop: 60,
    },
    startTitle: {
        fontSize: 32,
        fontWeight: "500",
        color: "#fff",
        fontFamily: "cerebri-sans-bold",
        marginTop: "7%",
        textAlign: "center"
    },
    startSubtitle: {
        fontSize: 14,
        fontWeight: "400",
        color: "#fff",
        opacity: 0.7,
        marginTop: "5%",
        fontFamily: "cerebri-sans-regular",
        textAlign: "center"
    },
    containerForm: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
        padding: 35,
        paddingTop: 0,
    },
    buttonMain: {
        backgroundColor: "#FFAD37",
        borderColor: "transparent",
        borderRadius: 8,
        color: "#000",
        display: "flex",
        fontWeight: "500",
        fontFamily: "cerebri-sans-semibold",
        fontSize: 13,
        height: 48,
        justifyContent: "center",
        width: "100%",
        marginBottom: 15,
    },
    buttonSecondary: {
        backgroundColor: "#000",
        borderColor: "transparent",
        borderRadius: 8,
        color: "#fff",
        display: "flex",
        fontWeight: "500",
        fontFamily: "cerebri-sans-semibold",
        fontSize: 13,
        height: 48,
        justifyContent: "center",
        width: "100%",
    },
    startIntro: {
        padding: 50,
        marginTop: "5%",
    },
    doctorImg: {
        height: "30%",
        width: "50%",
        position: 'relative'
    },
    doctorRow: {
        padding: "12%",
        paddingBottom: 0,
        paddingTop: 0,
        textAlign: "center",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "8%",
        marginTop: "15%",
    },
    doctorRowMin: {
        padding: "8%",
        paddingBottom: 0,
        paddingTop: 0,
        textAlign: "center",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "5%",
        marginTop: "5%",
    },
    doctorRowVector: {
        height: heightHalf,
        resizeMode: "contain",
    },
});

export default Start;
