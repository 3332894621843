import React, { Component } from "react";
import { Dimensions } from "react-native";
import {
  Image,
  Text,
  TextInput,
  View,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  Alert,
  StatusBar,
} from "react-native";
import { Button, Divider } from "react-native-paper";
import { postSuscribe, getTempOrder } from "../ArmFunctions";
import { login } from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import Svg, { Path, Circle } from "react-native-svg";
import * as Font from "expo-font";
import { Avatar, List, RadioButton, DefaultTheme } from "react-native-paper";

class PendingPayment extends Component {
  state = {
    value: "first",
    email: "",
    token: "",
    order: null,
  };

  componentDidMount() {
    Font.loadAsync({
      "poppins-semibold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
    });

    Font.loadAsync({
      "poppins-regular": require("../../assets/fonts/Poppins-Regular.ttf"),
    });
    AsyncStorage.getItem("user_token", (error, result) => {
      this.setState({
        token: result,
      });
      getTempOrder(result).then((res) => {
        this.setState({
          order: res,
        });
      });
    });
  }



  render() {
    var heightScreen = Dimensions.get("window").height;
    var date = this.state.order ? this.state.order.created_at : null

    return (
      <>
        <StatusBar barStyle="light-content" backgroundColor="#004A8F" />
        {heightScreen > 780 ? (
          <View style={styles.topBarExtra}></View>
        ) : (
            <View style={styles.topBarExtraMin}></View>
          )}
        <View style={styles.topBar}>
        <TouchableOpacity
            style={styles.backButton}
            onPress={() => this.props.navigation.navigate("Start")}
          >
            
              <Svg
              style={styles.backButtonIcon}
              width="50"
              height="40"
              viewBox="0 0 50 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                d="M50 0L50 40L-1.27162e-06 40L4.76837e-07 -2.18557e-06L50 0Z"
                fill="#004A8F"
              />
              <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.4142 19L30.707 14.7072L29.2928 13.293L22.5857 20.0001L29.2928 26.7072L30.707 25.293L26.414 21H35V19H26.4142Z"
                fill="white"
              />
            </Svg>
            
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Realiza tu pago</Text>
          <TouchableOpacity style={styles.backButton}>
            <Image
              style={styles.backButtonIcon}
              source={require("../../assets/backno.png")}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.container}>
          <Text style={styles.textLarge}>
            Presenta este comprobante en cualquier tienda OXXO del país para realizar
            el pago de tu compra.
     </Text>
          <View>
            <Divider />
            <View style={styles.row}>
              <Text style={styles.rowSubtitle}>Referencia</Text>
              <Text style={styles.rowTitle}>{this.state.order ? this.state.order.reference : '---'}</Text>
            </View>
            <Divider />
            <View style={styles.row}>
              <Text style={styles.rowSubtitle}>Expiración*</Text>
              <Text style={styles.rowTitle}>{this.state.order ? this.state.order.expiration_date : '---'}</Text>
            </View>
            <Divider />
            <View style={styles.row}>
              <Text style={styles.rowSubtitle}>Email</Text>
              <Text style={styles.rowTitle}>{this.state.order ? this.state.order.email : '---'}</Text>
            </View>
            <Divider />
            <View style={styles.row}>
              <Text style={styles.rowSubtitle}>Monto a Pagar**</Text>
              <Text style={styles.rowTitleBold}>{this.state.order ? `$ ${this.state.order.total}` : '$ ---'}</Text>
            </View>
            <Divider />
            <View style={styles.imageView}>
              <Image
                style={styles.oxxoImage}
                source={require("../../assets/oxxo-conekta.png")}
              />
            </View>
          </View>

          <Text style={styles.text}>
            Indica al cajero de la tienda el valor exacto que figura en el presente
            comprobante. *Te recordamos que una vez realizado el pago en el OXXO, el
            pago se hará efectivo 24 horas después y serás notificado vía correo
            electrónico. **OXXO cobrará una comisión adicional al momento de realizar
            el pago.
     </Text>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 20,
    paddingTop: 20,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 20,
  },
  rowSubtitle: {
    color: "#666666",
    fontFamily: "poppins-regular",
    fontSize: 12,
  },
  rowTitle: {
    color: "#222",
    fontFamily: "poppins-regular",
    fontSize: 14,
  },
  rowTitleBold: {
    color: "#222",
    fontFamily: "poppins-semibold",
    fontSize: 14,
  },
  textLarge: {
    marginTop: 15,
    marginBottom: 20,
    fontFamily: "poppins-regular",
    fontSize: 14,
  },
  text: {
    marginTop: 15,
    marginBottom: 15,
    fontFamily: "poppins-regular",
    fontSize: 13,
  },
  inputText: {
    backgroundColor: "white",
    borderBottomColor: "#E2E2E2",
    borderBottomWidth: 1,
    height: 50,
    color: "#000",
    paddingLeft: 0,
  },
  imageView: {
    alignItems: "center",
    marginTop: "auto",
  },
  buttonMain: {
    backgroundColor: "#FDCD25",
    borderColor: "transparent",
    borderRadius: 5,
    color: "#fff",
    display: "flex",
    fontWeight: "500",
    fontSize: 13,
    height: 46,
    justifyContent: "center",
    marginBottom: 30,
    marginTop: 30,
    width: "100%",
  },
  buttonText: {
    textAlign: "center",
    color: "black",
    fontWeight: "700",
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: "500",
    color: "#fff",
    fontFamily: "poppins-semibold",
  },
  backButtonIcon: {
    height: 20,
    width: 20,
  },
  topBar: {
    alignItems: "center",
    backgroundColor: "#004A8F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 45,
    paddingLeft: 20,
    paddingRight: 20,
  },
  topBarExtra: {
    backgroundColor: "#004A8F",
    height: 45,
  },
  topBarExtraMin: {
    backgroundColor: "#004A8F",
    height: 25,
  },
  startButtonText: {
    color: "#004A8F",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "right",
  },
  especialidadesList: {
    marginTop: 15,
  },
  especialidadesListSpec: {
    backgroundColor: "#F8BC47",
  },
  planesListPart: {
    fontFamily: "poppins-semibold",
    display: "flex",
    alignItems: "center",
    top: 8,
    right: 4,
    color: "#222",
    backgroundColor: "#fff",
    borderColor: "#fff",
    borderWidth: 1,
    position: "absolute",
  },
  planesListItem: {
    borderColor: "#E2E2E2",
    borderRadius: 5,
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 82,
    paddingBottom: 10,
    marginBottom: 20,
  },
  buttonAlt: {
    backgroundColor: "#fff",
    borderColor: "transparent",
    borderRadius: 5,
    color: "#000",
    display: "flex",
    fontWeight: "500",
    fontSize: 13,
    height: 48,
    justifyContent: "center",
    marginTop: 25,
    width: "100%",
  },
  buttonText: {
    textAlign: "center",
    color: "#000",
    fontWeight: "700",
  },
  buttonTextAlt: {
    textAlign: "center",
    textDecorationLine: "underline",
    color: "#000",
    fontWeight: "700",
  },
  oxxoImage: {
    margin: "auto",
    height: 30,
    width: 122,
    marginTop: 10,
    marginBottom: 5,
  },
});

export default PendingPayment;
