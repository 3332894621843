import React, { Component } from "react";
import { Dimensions } from "react-native";
import {
  Image,
  Text,
  View,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  Alert,
  StatusBar,
} from "react-native";
import { TextInput, Button } from "react-native-paper";
import { getCupones, postPasswordRecover } from "../ArmFunctions";
import { login } from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import Svg, { Path, Circle } from "react-native-svg";
import * as Font from "expo-font";

class RecoverSuccess extends Component {
  state = {
    email: "",
    emailError: "",
  };

  componentDidMount() {
    Font.loadAsync({
      "poppins-semibold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
    });

    Font.loadAsync({
      "poppins-regular": require("../../assets/fonts/Poppins-Regular.ttf"),
    });
  }

  render() {
    var heightScreen = Dimensions.get("window").height;

    return (
      <>
        <StatusBar barStyle="light-content" backgroundColor="#004A8F" />
        {heightScreen > 780 ? (
          <View style={styles.topBarExtra}></View>
        ) : (
            <View style={styles.topBarExtraMin}></View>
          )}
        <View style={styles.topBar}>
          <TouchableOpacity
            style={styles.backButton}
            onPress={() => this.props.navigation.navigate("Start")}
          >
            <Image
              style={styles.backButtonIcon}
              source={require("../../assets/back.png")}
            />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Recuperar Contraseña</Text>
          <TouchableOpacity style={styles.backButton}>
            <Image
              style={styles.backButtonIcon}
              source={require("../../assets/backno.png")}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.container}>
          <Text style={styles.text}>
            El correo ha sido enviado. Sigue las instrucciones para recuperar
            contraseña.
     </Text>
          <TouchableOpacity
            style={styles.buttonMain}
            onPress={() => {
              this.props.navigation.navigate("Login");
            }}
          >
            <Text style={styles.buttonText}>Iniciar Sesión</Text>
          </TouchableOpacity>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 20,
    paddingTop: 50,
  },
  text: {
    marginTop: 15,
    marginBottom: 15,
    fontFamily: "poppins-regular",
  },
  inputText: {
    backgroundColor: "white",
    height: 55,
    color: "#000",
    paddingLeft: 0,
    fontFamily: "poppins-regular",
    fontSize: 14,
    paddingHorizontal: 0,
  },
  buttonMain: {
    backgroundColor: "#FFAD37",
    borderColor: "transparent",
    borderRadius: 5,
    color: "#fff",
    display: "flex",
    fontWeight: "500",
    fontSize: 13,
    height: 46,
    justifyContent: "center",
    marginTop: 30,
    width: "100%",
    fontFamily: "poppins-semibold",
  },
  buttonText: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
    fontFamily: "poppins-semibold",
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: "500",
    color: "#fff",
    fontFamily: "poppins-semibold",
  },
  backButtonIcon: {
    height: 20,
    width: 20,
  },
  topBar: {
    alignItems: "center",
    backgroundColor: "#004A8F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 45,
    paddingLeft: 20,
    paddingRight: 20,
  },
  topBarExtra: {
    backgroundColor: "#004A8F",
    height: 45,
  },
  topBarExtraMin: {
    backgroundColor: "#004A8F",
    height: 25,
  },
  startButtonText: {
    color: "#004A8F",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "right",
  },
});

export default RecoverSuccess;
