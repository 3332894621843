import React, { Component } from "react";
import * as Font from "expo-font";
import { AsyncStorage, Alert, Dimensions, Platform } from "react-native";
import {
  Image,
  Text,
  TextInput,
  View,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
  Linking,
  StatusBar,
} from "react-native";
import {
  getEspecialidades,
  getCasos,
  getNiveles,
  getUserNivel,
  postResponder,
  postUpdateCountCaso,
  getUserNivelTemaTipo,
} from "../ArmFunctions";
import {
  List,
  Avatar,
  Button,
  Card,
  RadioButton,
  Title,
  Paragraph,
  Divider,
} from "react-native-paper";
import Lightbox from "react-native-lightbox";
import Svg, { Path, Circle } from "react-native-svg";
import HTML from "react-native-render-html";
// import ImageView from "react-native-image-viewing";
import { TouchableHighlight } from "react-native-gesture-handler";
import { globalStyles } from "../Layout";

const window = Dimensions.get('window');

class Casos extends Component {
  state = {
    test_content: this.props.navigation.state.params.test_content,
    niveles: [],
    casos: [],
    nivel_usuario: this.props.navigation.state.params.test_content.nivel,
    user_name: "",
    token: "",
    value: "first",
    display: false,
    selected_answers: [],
    caso_index: 0,
    current_caso: 0,
    question_image : "",
    visible_question : false
  };

  componentDidMount() {
    if (Platform.OS === 'web') {
      const token = localStorage.getItem('user_token');
      const user_name = localStorage.getItem('user_name');

      this.setState({
        token,
        user_name
      }, () => {
        this.getTestContent(this.state.test_content);
      })

    } else {
      AsyncStorage.getItem("user_token", (error, result) => {
        this.setState(
          {
            token: result,
          },
          () => {
            AsyncStorage.getItem("user_name", (error, result) => {
              this.setState({
                user_name: result,
              });
            });
            this.getTestContent(this.state.test_content);
          }
        );
      });
    }

    Font.loadAsync({
      "poppins-semibold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
    });

    Font.loadAsync({
      "poppins-regular": require("../../assets/fonts/Poppins-Regular.ttf"),
    });
  }

  validateSelections = () => {
    if (
      this.state.selected_answers.length !== this.state.casos[0].preguntas.length
    ) {
      Alert.alert("", "Selecciona todas tus respuestas.");
      return false;
    } else {
      return true;
    }
  };

  getTestContent = (test_content) => {
    const casos = test_content.casos;
    const current_index = this.state.caso_index;
    let current_caso = 0;
    casos.map((caso, index_caso) => {
      var cant_contestadas = 0;
      caso.preguntas.map((pregunta, index_pregunta) => {
        test_content.preguntas_contestadas.map((pregunta_contestada) => {
          if (pregunta.id == pregunta_contestada.pregunta_id) {
            cant_contestadas = cant_contestadas + 1;
            if (cant_contestadas == caso.preguntas.length) {
              casos[index_caso] = {
                ...casos[index_caso],
                answered: true,
              };
              current_caso = current_caso + 1;
            }
            if (current_caso == casos.length) {
              current_caso = 0;
            }
            casos[index_caso].preguntas[index_pregunta] = {
              ...casos[index_caso].preguntas[index_pregunta],
              answered: true,
              respuesta_contestada_id: pregunta_contestada.respuesta_id,
            };
            pregunta.respuestas.map((respuesta, index_respuesta) => {
              if (respuesta.id == pregunta_contestada.respuesta_id) {
                casos[index_caso].preguntas[index_pregunta].respuestas[
                  index_respuesta
                ] = {
                  ...casos[index_caso].preguntas[index_pregunta].respuestas[
                  index_respuesta
                  ],
                  selected: true,
                };
              }
            });
          }
        });
      });
    });
    this.setState(
      {
        casos: [casos[current_caso]],
        current_caso,
        caso_index: current_caso,
      },
      () => {
        console.log(casos);
        console.log(current_index);
      }
    );
  };

  getTestContentAnswer = (test_content) => {
    const casos = test_content.casos;
    const current_index = this.state.caso_index;
    let current_caso = 0;
    casos.map((caso, index_caso) => {
      var cant_contestadas = 0;
      caso.preguntas.map((pregunta, index_pregunta) => {
        test_content.preguntas_contestadas.map((pregunta_contestada) => {
          if (pregunta.id == pregunta_contestada.pregunta_id) {
            cant_contestadas = cant_contestadas + 1;
            if (cant_contestadas == caso.preguntas.length) {
              casos[index_caso] = {
                ...casos[index_caso],
                answered: true,
              };
            }
            casos[index_caso].preguntas[index_pregunta] = {
              ...casos[index_caso].preguntas[index_pregunta],
              answered: true,
              respuesta_contestada_id: pregunta_contestada.respuesta_id,
            };
            pregunta.respuestas.map((respuesta, index_respuesta) => {
              if (respuesta.id == pregunta_contestada.respuesta_id) {
                casos[index_caso].preguntas[index_pregunta].respuestas[
                  index_respuesta
                ] = {
                  ...casos[index_caso].preguntas[index_pregunta].respuestas[
                  index_respuesta
                  ],
                  selected: true,
                };
              }
            });
          }
        });
      });
    });
    this.setState(
      {
        casos: [casos[current_index]],
      },
      () => {
        console.log(casos);
        console.log(current_index);
      }
    );
  };

  changeCaso = () => {
    const actual_index = this.state.caso_index;
    const cantidad_casos = this.state.test_content.casos.length;
    if (actual_index < cantidad_casos) {
      var new_index = 0;
      new_index = actual_index + 1;
      this.setState({
        caso_index: new_index,
        casos: [this.state.test_content.casos[new_index]],
        selected_answers: [],
      });
    } else if (actual_index == cantidad_casos) {
      this.setState({
        ended: true,
      });
    }
  };

  handleAnswers = (i) => {
    this.setState((previousState) => ({ display: !previousState.content }));
    const isValid = this.validateSelections();
    if (isValid) {
      if (i < this.state.selected_answers.length) {
        let answers = this.state.selected_answers;
        let answer = answers[i];
        let no_domino = false;
        let etapa_actual = "";
        if (this.state.nivel_usuario[0].name == "no lo domino") {
          no_domino = true;
          etapa_actual = "E-2";
        } else if (
          this.state.nivel_usuario[0].parent_id == null &&
          this.state.nivel_usuario[0].name !== "no lo domino"
        ) {
          etapa_actual = "E-1";
        } else {
          etapa_actual = "E-3";
        }
        answer = {
          ...answer,
          token: this.state.token,
          nivel_usuario_id: this.state.test_content.id,
          no_domino: no_domino,
          etapa_actual: etapa_actual,
        };
        postResponder(answer).then((res) => {
          this.handleAnswers(i + 1);
        });
      } else {
        this.afterAnswer();
      }
    }
  };

  afterAnswer = () => {
    const data = {
      token: this.state.token,
      nivel_id: this.state.nivel_usuario[0].id,
      especialidad_id: this.state.nivel_usuario[0].especialidad_id,
      tema_id : this.state.test_content.tema_id,
      tipo_id : this.state.test_content.tipo_id
    };
    getUserNivelTemaTipo(data).then((res) => {
      this.getTestContentAnswer(res);
    });
    const data_caso_count = {
      nivel_usuario_id: this.state.test_content.id,
      caso_index: this.state.caso_index + 1,
      token: this.state.token,
    };
    //postUpdateCountCaso(data_caso_count);
  };
  
  render() {
    const error_display = false;
    
    
    return (
      <View
      style={globalStyles.globalContainer}
    >
      <ScrollView
        style={Platform.OS === 'web' ? globalStyles.screenContainerDesktop : globalStyles.screenContainerMobile}
        showsVerticalScrollIndicator={false}
        ref="_scrollView"
      >
        <StatusBar barStyle="light-content" backgroundColor="#004A8F" />
        <View>
          {this.state.casos.map((caso, caso_index) => (
            <>
              <Card key={caso.id} style={styles.cardContainer}>
                <Card.Content style={styles.cardContainerContent}>
                  <View style={styles.topBarSub}>
                    <Text style={styles.headerTitle}>Detalle del Caso</Text>
                    <Text style={styles.headerCounter}>
                      {this.state.caso_index +
                        1 +
                        " de " +
                        this.state.test_content.casos.length}
                    </Text>
                  </View>
                  {/*<Text style={styles.descripcionText}>{caso.descripcion}</Text>*/}
                  <HTML source={{ html: caso.descripcion }} 
                        classesStyles={styles.descripcionText}
                        contentWidth={window.width}
                        />
                  {caso.imagenes
                    ? this.state.test_content.casos[this.state.caso_index].imagenes.map((imagen,index) => (
                      
                      <TouchableHighlight key={index}
                      onPress={()=>{this.setState({
                          question_image : [{uri : `https://premed.lagom.agency/storage/${imagen.path_file}` }],
                          visible_question : true
                        })
                        }}>
                      <Image
                        style={{ height: 200, resizeMode : 'contain', marginBottom: 10 }}
                        source={{ uri: `https://premed.lagom.agency/storage/${imagen.path_file.trim()}` }}
                        //source={{ uri: `${this.state.image}` }}
                      />
                    </TouchableHighlight>
                    ))
                    : null}
                    
                  <Divider style={styles.dividerLine} />
                  {/*<ImageView
                        images={this.state.question_image}
                        imageIndex={0}
                        visible={this.state.visible_question}
                        onRequestClose={() => {this.setState({
                          visible_question : false
                        })}}
                      />*/}
                  {caso.preguntas.map((pregunta, pregunta_index) => (
                    <View key={pregunta.id}>
                      <View style={{flex:1, flexDirection: 'row'}}>
                      <Text style={styles.questionTitle}>
                        {pregunta_index + 1 + ". "}
                      </Text>
                      
                        <HTML source={{ html: pregunta.titulo }} 
                              contentWidth={window.width}
                            />
                      </View>
                      {this.state.casos[caso_index].preguntas[pregunta_index].archivos.map(
                        (archivo, index) => (
                          archivo.path_file.includes("png") || archivo.path_file.includes("jpeg") || archivo.path_file.includes("jpg")?
                          <TouchableHighlight key={index} 
                          onPress={()=>{this.setState({
                              //question_image : [{uri : `https://premed.lagom.agency/storage/${archivo.path_file.trim()}` }],
                             // visible_question : true
                            })
                            }}>
                          <Image
                            style={{ height: 200, resizeMode : 'contain', marginBottom: 10 }}
                            source={{ uri: `https://premed.lagom.agency/storage/${archivo.path_file.trim()}` }}
                            //source={{ uri: `${this.state.image}` }}
                          />
                        </TouchableHighlight>
                        
                        :
                        <List.Item
                        description={`Documento de pregunta ${index+1}`}
                        descriptionStyle={styles.fileButton}
                        descriptionNumberOfLines={15}
                        style={styles.FilesListItem}
                        left={() => (
                          <View
                          style={styles.centerCheck}
                          >
                            <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Path d="M6 2C4.896 2 4 2.896 4 4V20C4 21.104 4.896 22 6 22H18C19.104 22 20 21.104 20 20V8L14 2H6ZM14 9C13.447 9 13 9 13 9V4L18 9H14Z" fill="#1B7CED"/>
                            </Svg>
                          </View>
                        )}
                          onPress={() => {
                          Linking.openURL(
                            `https://premed.lagom.agency/storage/${archivo.path_file.trim()}`
                          );
                          }}
                        />
                        )
                      )}
                      
                      <View>
                        <List.Section style={styles.especialidadesList}>
                          {pregunta.respuestas.map((respuesta, respuesta_index) => (
                            <List.Item
                              description={respuesta.valor}
                              descriptionStyle={
                                pregunta.answered &&
                                  respuesta.id == pregunta.respuesta_correcta_id
                                  ? {
                                    color: "#fff",
                                    top: -6,
                                    fontSize: 14,
                                    fontFamily: "poppins-regular",
                                  }
                                  : pregunta.answered && respuesta.selected
                                    ? respuesta.valor == "no lo domino"
                                      ? {
                                        color: "#004A8F",
                                        top: -6,
                                        fontSize: 14,
                                        textAlign: "center",
                                        fontFamily: "poppins-semibold",
                                      }
                                      : {
                                        color: "white",
                                        top: -6,
                                        fontSize: 14,
                                        fontFamily: "poppins-regular",
                                      }
                                    : {
                                      color: "#000",
                                      top: -6,
                                      fontSize: 14,
                                      fontFamily: "poppins-regular",
                                    }
                              }
                              descriptionNumberOfLines={15}
                              key={respuesta.id}
                              style={
                                pregunta.answered &&
                                  respuesta.id == pregunta.respuesta_correcta_id
                                  ? styles.questionButtonRight
                                  : pregunta.answered && respuesta.selected
                                    ? respuesta.valor == "no lo domino"
                                      ? styles.questionButtonTrouble
                                      : styles.questionButtonWrong
                                    : styles.planesListItem
                              }
                              value={pregunta.id}
                              onPress={() => {
                                let respuestas_copy = this.state.casos[caso_index].preguntas[
                                  pregunta_index
                                ].respuestas;
                                respuestas_copy.map((respuesta_c, idx) => {
                                  respuestas_copy[idx] = {
                                    ...respuestas_copy[idx],
                                    selected: false,
                                  };
                                });
                                if (respuestas_copy[respuesta_index].id == respuesta.id) {
                                  respuestas_copy[respuesta_index] = {
                                    ...respuestas_copy[respuesta_index],
                                    selected: true,
                                  };
                                }
                                const data = {
                                  pregunta_id: pregunta.id,
                                  respuesta_id: respuesta.id,
                                  nivel_usuario_id: this.state.test_content.id,
                                  especialidad_id: caso.especialidad_id,
                                  pregunta_index: pregunta_index,
                                  caso_index: caso_index,
                                };
                                const answers_copy = this.state.selected_answers.filter(
                                  (answer) => answer.pregunta_id !== pregunta.id
                                );
                                answers_copy.push(data);

                                this.setState({
                                  respuestas_copy,
                                  selected_answers: answers_copy,
                                });
                              }}
                              right={() => (
                                <View
                                  style={
                                    pregunta.answered &&
                                      respuesta.id == pregunta.respuesta_correcta_id
                                      ? styles.centerCheck
                                      : pregunta.answered && respuesta.selected
                                        ? respuesta.valor == "no lo domino"
                                          ? styles.centerCheck
                                          : styles.centerCheck
                                        : respuesta.selected
                                          ? styles.centerCheck
                                          : styles.centerCheck_not_answered
                                  }
                                >
                                  {pregunta.answered &&
                                    respuesta.id == pregunta.respuesta_correcta_id ? (
                                      <Svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <Path
                                          d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
                                          fill="white"
                                        />
                                        <Path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M14.7071 7.70706L8.46542 13.9488L5.30127 10.8582L6.69875 9.42743L8.44888 11.1369L13.2929 6.29285L14.7071 7.70706Z"
                                          fill="#6AB448"
                                        />
                                      </Svg>
                                    ) : pregunta.answered && respuesta.selected ? (
                                      respuesta.valor == "no lo domino" ? (
                                        <Svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <Path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M9.70711 11.5977L14.1093 15.9999L15.9999 14.1093L11.5977 9.70711L15.9999 5.30493L14.1093 3.41431L9.70711 7.81649L5.30493 3.41431L3.41431 5.30493L7.81649 9.70711L3.41431 14.1093L5.30493 15.9999L9.70711 11.5977Z"
                                            fill="#004A8F"
                                          />
                                        </Svg>
                                      ) : (
                                          <Svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <Path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M9.70711 11.5977L14.1093 15.9999L15.9999 14.1093L11.5977 9.70711L15.9999 5.30493L14.1093 3.41431L9.70711 7.81649L5.30493 3.41431L3.41431 5.30493L7.81649 9.70711L3.41431 14.1093L5.30493 15.9999L9.70711 11.5977Z"
                                              fill="white"
                                            />
                                          </Svg>
                                        )
                                    ) : respuesta.selected ? (
                                      <RadioButton
                                        value={respuesta.id}
                                        color="black"
                                        status={respuesta.selected ? "checked" : "unchecked"}
                                        name={"respuesta " + respuesta_index}
                                        theme={{ fontSize: 12 }}
                                      />
                                    ) : (
                                          <RadioButton
                                            value={respuesta.id}
                                            color="black"
                                            status={respuesta.selected ? "checked" : "unchecked"}
                                            name={"respuesta " + respuesta_index}
                                            theme={{ fontSize: 12 }}
                                          />
                                        )}
                                </View>
                              )}
                            />
                          ))}
                        </List.Section>
                      </View>

                      {pregunta.answered ? (
                        <View>
                          <Text style={styles.questionRetro}>Retroalimentación</Text>
                          {/*<Text style={styles.textRetro}>{pregunta.retro}</Text>*/}
                          <HTML source={{ html: pregunta.retro }} 
                            classesStyles={styles.textRetro}
                            contentWidth={window.width}
                          />
                          <Divider style={styles.dividerLine} />
                        </View>
                      ) : null}
                    </View>
                  ))}
                  {caso.answered ? (
                    <View>
                      <Text style={styles.questionRetro}>Retroalimentación del Caso</Text>
                      {/*<Text style={styles.textRetro}>{caso.retro_general}</Text>*/}
                      <HTML source={{ html: caso.retro_general }} 
                            classesStyles={styles.textRetro}
                            contentWidth={window.width}
                          />
                      <Divider style={styles.dividerLine} />
                    </View>
                  ) : null}
                  {this.state.caso_index + 1 !== this.state.test_content.casos.length &&
                    caso.answered ? (
                      <TouchableOpacity
                        style={styles.buttonAlt}
                        onPress={() => {
                          this.refs._scrollView.scrollTo({
                            x: 20,
                            y: 25,
                            animated: true,
                          });
                          setTimeout(() => {
                            this.changeCaso();
                          }, 400);
                        }}
                      >
                        <Text style={styles.buttonTextAlt}>Continuar</Text>
                      </TouchableOpacity>
                    ) : this.state.caso_index + 1 ==
                      this.state.test_content.casos.length && caso.answered ? (
                        <TouchableOpacity
                          style={styles.buttonMain}
                          onPress={() => {
                            this.props.navigation.navigate("Stages");
                          }}
                        >
                          <Text style={styles.buttonText}>Regresar</Text>
                        </TouchableOpacity>
                      ) : (
                        <TouchableOpacity
                          style={styles.buttonAlt}
                          onPress={() => {
                            Alert.alert(
                              "",
                              "Revisa tus respuestas y continua con el siguiente caso."
                            );
                            this.handleAnswers(0);
                            this.refs._scrollView.scrollTo({
                              x: 20,
                              y: 25,
                              animated: true,
                            });
                          }}
                        >
                          <Text style={styles.buttonTextAlt}>Responder</Text>
                        </TouchableOpacity>
                      )}
                </Card.Content>
              </Card>
            </>
          ))}
        </View>
        <View style={{ paddingBottom: 50 }}></View>
      </ScrollView>
    </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 20,
    paddingTop: 5,
  },
  containerTitle: {
    flex: 1,
    backgroundColor: "white",
    padding: 0,
    paddingTop: 0,
  },
  inputText: {
    backgroundColor: "white",
    borderBottomColor: "#E2E2E2",
    borderBottomWidth: 1,
    height: 50,
    color: "#000",
    paddingLeft: 0,
  },
  buttonMain: {
    backgroundColor: "#FFAD37",
    borderColor: "transparent",
    borderRadius: 5,
    color: "#fff",
    display: "flex",
    fontWeight: "500",
    fontSize: 13,
    height: 46,
    justifyContent: "center",
    width: "100%",
  },
  descripcionText: {
    fontFamily: "poppins-regular",
  },
  buttonAlt: {
    backgroundColor: "#FDCD25",
    borderColor: "transparent",
    borderRadius: 5,
    color: "#000",
    display: "flex",
    fontWeight: "500",
    fontSize: 13,
    height: 46,
    justifyContent: "center",
    width: "100%",
  },
  headerCounter: {
    fontSize: 11,
  },
  questionButtonRight: {
    backgroundColor: "#6AB448",
    borderColor: "#6AB448",
    borderWidth: 1,
    padding: 15,
    marginBottom: 15,
    borderRadius: 5,
  },
  questionButtonWrong: {
    backgroundColor: "#004A8F",
    borderColor: "#004A8F",
    borderWidth: 1,
    padding: 15,
    marginBottom: 15,
    borderRadius: 5,
  },
  questionButtonTrouble: {
    backgroundColor: "#FFE9EA",
    borderRadius: 5,
    marginBottom: 15,
  },
  buttonText: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
    textTransform: "capitalize",
    letterSpacing: 0,
    fontFamily:"poppins-regular"
  },
  buttonTextAlt: {
    textAlign: "center",
    color: "#000",
    fontWeight: "700",
    textTransform: "capitalize",
    letterSpacing: 0,
    fontFamily:"poppins-regular"
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: "500",
    color: "#666",
    fontFamily: "poppins-regular",
  },
  questionTitle: {
    fontSize: 14,
    fontWeight: "500",
    color: "#000",
    fontFamily: "poppins-semibold",
    marginBottom: 30,
  },
  questionRetro: {
    fontSize: 14,
    color: "#666",
    fontFamily: "poppins-regular",
    marginBottom: 15,
    marginTop: 15,
  },
  textRetro: {
    fontFamily: "poppins-regular",
  },
  questionButton: {
    borderColor: "#F2F2F2",
    borderWidth: 1,
    color: "#000",
    letterSpacing: 0,
    textAlign: "left",
    marginBottom: 15,
  },
  backButtonIcon: {
    height: 20,
    width: 20,
  },
  topBar: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 20,
    marginBottom: 20,
    marginTop: 10,
  },
  topBarSub: {
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 20,
    marginBottom: 30,
  },
  startButtonText: {
    color: "#004A8F",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "right",
  },
  startButtonRecover: {
    color: "black",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "center",
  },
  especialidadesListSpec: {
    backgroundColor: "#F8BC47",
  },
  especialidadesListPart: {
    fontFamily: "poppins-semibold",
    display: "flex",
    alignItems: "center",

    color: "#222",
  },
  especialidadesListItem: {
    borderBottomColor: "#E2E2E2",
    borderBottomWidth: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 80,
  },
  planesListPart: {
    fontFamily: "poppins-semibold",
    display: "flex",
    alignItems: "center",
    top: 6,
    right: 4,
    color: "#222",
    backgroundColor: "#fff",
    borderColor: "#E2E2E2",
    borderWidth: 1,
    position: "absolute",
  },
  planesListItem: {
    borderColor: "#E2E2E2",
    borderRadius: 5,
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "auto",
    marginBottom: 15,
  },
  hide_retro: {
    display: "none",
  },
  cardContainer: {
    shadowColor: "#fff",
  },
  cardContainerContent: {
    padding: 0,
  },
  dividerLine: {
    marginBottom: 30,
    marginTop: 30,
  },
  tinyLogo: {
    width: 50,
    height: 50,
    flex: 1,
    resizeMode: "cover",
  },
  centerCheck: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  centerCheck_not_answered: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
  },
  lightboxThumb: {
    marginTop: 20,
  },
  radio_button: {
    display: "none",
  },
});

export default Casos;
