import React, { Component } from "react";
import {
    Image,
    Text,
    View,
    Button,
    StyleSheet,
    FlatList,
    TouchableOpacity,
    AsyncStorage,
    StatusBar,
    ImageBackground,
} from "react-native";
import axios from "axios";
import * as Font from "expo-font";
import { getCupones } from "../ArmFunctions";
import Pagination from "react-native-pagination";
import _ from "lodash";
import { Dimensions } from "react-native";

var width = Dimensions.get("window").width;
var height = Dimensions.get("window").height;
var heightHalf = Dimensions.get("window").height / 2;

export default class Index extends Component {
    state = {
        token: "",
        user_name: "",
        cupones: [],
    };
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    key: 1,
                    id: 1,
                    title: "Bienvenid@ a tu nueva\ncuenta DirectUNI",
                    text:
                        "En ésta aplicación podrás simular el \n examen de admisión.",
                    image: "",
                    imageCover: require("../../assets/tutorial-slide-1.png"),
                    imageCoverMin: require("../../assets/tutorial-slide-1.png"),
                    imageDots: require("../../assets/slider-1.png"),
                    imageStyle: styles.slideCoverShow,
                    buttonStyle: styles.buttonNoShow,
                },
                {
                    key: 2,
                    id: 2,
                    title6: "¿Cómo funciona?",
                    text:
                        "En el menú principal encontrarás el listado de las pruebas para cada una de las diferentes especialidades, éstas se dividen en 3 etapas.",
                    image: require("../../assets/loginWelcome4.png"),
                    imageCover: require("../../assets/tutorial-slide-2.png"),
                    imageCoverMin: require("../../assets/tutorial-slide-2.png"),
                    imageDots: require("../../assets/slider-2.png"),
                    imageStyle: styles.slideCoverShow,
                    buttonStyle: styles.buttonNoShow,
                },
                {
                    key: 3,
                    id: 3,
                    title2: "Etapa 1",
                    text2:
                        "Contestarás una selección de caso que te ayudarán al estudio y repaso para la prueba.",
                    title3: "Etapa 2",
                    text3:
                        "Aquellos casos que hayas contestado erróneamente o no los domines aparecerán de nuevo en esta etapa para un nuevo repaso.",
                    title4: "Etapa 3",
                    text4:
                        "De nueva cuenta contestarás todos los casos y obtendrás tu resultado.",
                    image: require("../../assets/loginWelcome2.png"),
                    imageCover: require("../../assets/tutorial-slide-3.png"),
                    imageCoverMin: require("../../assets/tutorial-slide-3.png"),
                    imageDots: require("../../assets/slider-3.png"),
                    imageStyle: styles.slideCoverShowAlt,
                    buttonStyle: styles.buttonNoShow,
                },
                {
                    key: 4,
                    id: 4,
                    title5: "¿Estás list@?",
                    text2:
                        "Te recomendamos que establezcas una meta de casos por día, repasa la retroalimentación de cada uno de ellos y estúdialos detenidamente. Ésta es una gran herramienta para el Exámen Nacional.",
                    image: require("../../assets/loginWelcome3.png"),
                    imageCover: require("../../assets/tutorial-slide-4.png"),
                    imageCoverMin: require("../../assets/tutorial-slide-4.png"),
                    imageStyle: styles.slideCoverShow,
                    buttonStyle: styles.buttonMain,
                },
            ],
        };
    }
    componentDidMount() {
        AsyncStorage.getItem("user_token", (error, result) => {
            this.setState(
                {
                    token: result,
                },
                () => {
                    AsyncStorage.getItem("user_name", (error, result) => {
                        this.setState({
                            user_name: result,
                        });
                    });
                    getCupones(this.state.token).then((res) => {
                        this.setState({
                            cupones: res,
                        });
                    });
                }
            );
        });

        Font.loadAsync({
            "poppins-semibold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
        });

        Font.loadAsync({
            "poppins-regular": require("../../assets/fonts/Poppins-Regular.ttf"),
        });
    }

    willFocus = this.props.navigation.addListener("willFocus", (payload) => {

    });

    _renderItem = ({ item }) => {
        var heightScreen = Dimensions.get("window").height;
        return (
            <View style={styles.slide}>
                <StatusBar barStyle="light-content" backgroundColor="#004A8F" />
                {heightScreen > 780 ? (
                    <ImageBackground source={item.imageCover} style={{ width: "100%", height: '100%' }} >
                         {item.title == null ? (
                                <View />
                            ) : (
                                    <View style={styles.slideHeader}>
                                        <Text style={styles.slideTitle1}>{item.title}</Text>
                                        <Text style={styles.slideText1}>{item.text}</Text>
                                    </View>
                                )}
                                 {item.title6 == null ? (
                                <View />
                            ) : (
                                    <View style={styles.slideHeader}>
                                        <Text style={styles.slideTitle}>{item.title6}</Text>
                                        <Text style={styles.slideText}>{item.text}</Text>
                                    </View>
                                )}
                         {item.title5 == null ? (
                    <View />
                ) : (
                        <View style={styles.slideFinal}>
                            <Text style={styles.slideTitleAlt3}>{item.title5}</Text>
                            <Text style={styles.slideTextAlt}>{item.text2}</Text>

                            <TouchableOpacity
                                style={item.buttonStyle}
                                onPress={() => this.props.navigation.navigate("IndexEspecialidades")}
                            >
                                <Text style={styles.buttonText}>Comenzar</Text>
                            </TouchableOpacity>
                        </View>
                    )}
                {item.title2 == null ? (
                    <View />
                ) : (
                        <View style={styles.slideRed}>
                            <Text style={styles.slideTitleAlt}>{item.title2}</Text>
                            <Text
                                style={heightScreen > 780 ? styles.slideTextAlt : styles.slideTextAltMin}
                            >
                                {item.text2}
                            </Text>
                            <Text style={styles.slideTitleAlt2}>{item.title3}</Text>
                            <Text
                                style={heightScreen > 780 ? styles.slideTextAlt : styles.slideTextAltMin}
                            >
                                {item.text3}
                            </Text>
                            <Text style={styles.slideTitleAlt2}>{item.title4}</Text>
                            <Text
                                style={heightScreen > 780 ? styles.slideTextAlt : styles.slideTextAltMin}
                            >
                                {item.text4}
                            </Text>
                        </View>
                    )}
                    </ImageBackground>
                ) : (
                        <ImageBackground
                            source={item.imageCoverMin}
                            style={{ width: "100%", height: "100%" }}
                        >
                             {item.title == null ? (
                                <View />
                            ) : (
                                    <View style={styles.slideHeader}>
                                        <Text style={styles.slideTitle1}>{item.title}</Text>
                                        <Text style={styles.slideText1}>{item.text}</Text>
                                    </View>
                                )}
                                 {item.title6 == null ? (
                                <View />
                            ) : (
                                    <View style={styles.slideHeader}>
                                        <Text style={styles.slideTitle}>{item.title6}</Text>
                                        <Text style={styles.slideText}>{item.text}</Text>
                                    </View>
                                )}
                         {item.title5 == null ? (
                    <View />
                ) : (
                        <View >
                            <Text style={styles.slideTitleAlt3}>{item.title5}</Text>
                            <Text style={styles.slideTextAlt}>{item.text2}</Text>

                            <TouchableOpacity
                                style={item.buttonStyle}
                                onPress={() => this.props.navigation.navigate("IndexEspecialidades")}
                            >
                                <Text style={styles.buttonText}>Comenzar</Text>
                            </TouchableOpacity>
                        </View>
                    )}
                {item.title2 == null ? (
                    <View />
                ) : (
                        <View >
                            <Text style={styles.slideTitleAlt}>{item.title2}</Text>
                            <Text
                                style={heightScreen > 780 ? styles.slideTextAlt : styles.slideTextAltMin}
                            >
                                {item.text2}
                            </Text>
                            <Text style={styles.slideTitleAlt2}>{item.title3}</Text>
                            <Text
                                style={heightScreen > 780 ? styles.slideTextAlt : styles.slideTextAltMin}
                            >
                                {item.text3}
                            </Text>
                            <Text style={styles.slideTitleAlt2}>{item.title4}</Text>
                            <Text
                                style={heightScreen > 780 ? styles.slideTextAlt : styles.slideTextAltMin}
                            >
                                {item.text4}
                            </Text>
                        </View>
                    )}
                        </ImageBackground>
                    )}

               
               
                <Image style={styles.sliderDots} source={item.imageDots} />
            </View>
        );
    };
    _keyExtractor = (item, index) => item.id.toString();
    onViewableItemsChanged = ({ viewableItems, changed }) =>
        this.setState({ viewableItems });
    render() {
        return (
            <View style={styles.container}>
                <View style={styles.logoContainer}>
                    <View style={styles.sliderContainer}>
                        <FlatList
                            ref={(r) => (this.refs = r)}
                            data={this.state.items}
                            horizontal
                            keyExtractor={this._keyExtractor}
                            onViewableItemsChanged={this.onViewableItemsChanged}
                            pagingEnabled
                            renderItem={this._renderItem}
                            showsHorizontalScrollIndicator={false}
                        />
                        <Pagination
                            horizontal
                            dotOnPress={(o) => console.log(" clicked: ", o)}
                            hideEmptyDots
                            pagingEnabled
                            startDotStyle={styles.noDisplay}
                            endDotStyle={styles.noDisplay}
                            dotIconName="ios-close"
                            hideEmptyDots
                            dotEmptyHide
                            dotIconColorActive="#000"
                            dotIconColorNotActive="rgba(0,0,0,0.25)"
                            dotIconSizeActive={25}
                            listRef={this.refs}
                            paginationVisibleItems={this.state.viewableItems}
                            paginationItems={this.state.items}
                            paginationItemPadSize={3}
                            dotTextHide={true}
                            dotIconHide={true}
                        />
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#004A8F",
    },
    viewContent: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center'  
    },
    logoContainer: {
        alignItems: "center",
        flexGrow: 1,
        justifyContent: "center",
    },
    sliderContainer: {
        alignItems: "center",
        justifyContent: "center",
        height: height,
        width: width,
    },
    slide: {
        display: "flex",
        alignSelf: "stretch",
        width: width,
    },
    slideRed: {
        padding: 50,
        paddingBottom: 200,
        paddingTop: "90%",
        zIndex: 99,

    },
    imageStyle: {
        width: width,
        height: 472,
        zIndex: 99,
    },
    imageCover: {
        paddingTop: 400,
        zIndex: 1,
    },
    sliderDots: {
        height: 8,
        width: 62,
        margin: "auto",
        alignSelf: "center",
        bottom: "6%",
        position: "absolute",
        zIndex: 999,
    },
    sliderDots2: {
        height: 8,
        width: 62,
        margin: "auto",
        alignSelf: "center",
        marginTop: 40,
    },
    slideCoverShow: {
        height: 120,
        width: 120,
        marginBottom: 40,
    },
    slideCoverShowAlt: {
        height: 120,
        width: 120,
        marginBottom: 0,
    },
    slideCoverNoShow: {
        height: 1,
        width: 120,
        marginBottom: 100,
    },
    slideHeader: {
        padding: 50,
        marginTop: "120%",
        textAlign: "center",
    },
    slideTitle1: {
        fontFamily: "poppins-semibold",
        fontSize: 18,
        marginBottom: 20,
        textAlign: "center",
        color: "#FFFFFF"
    },
    slideTitle: {
        fontFamily: "poppins-semibold",
        fontSize: 18,
        marginBottom: 20,
        textAlign: "center",
    },
    slideTitleAlt: {
        fontFamily: "poppins-semibold",
        fontSize: 14,
        marginBottom: 10,
        textAlign: "center",
        color: "#fff",
    },
    slideTitleAlt2: {
        fontFamily: "poppins-semibold",
        fontSize: 14,
        marginBottom: 10,
        marginTop: "8%",
        textAlign: "center",
        color: "#fff",
    },
    slideTitleAlt3: {
        fontFamily: "poppins-semibold",
        fontSize: 18,
        marginBottom: 40,
        marginTop: 40,
        textAlign: "center",
        color: "#fff",
    },
    slideText1: {
        fontFamily: "poppins-regular",
        fontSize: 14,
        lineHeight: 18,
        textAlign: "center",
        color: "#FFFFFF"
    },
    slideText: {
        fontFamily: "poppins-regular",
        fontSize: 14,
        lineHeight: 18,
        textAlign: "center",
    },
    slideTextAlt: {
        color: "#fff",
        fontFamily: "poppins-regular",
        fontSize: 14,
        lineHeight: 18,
        textAlign: "center",
        opacity: .4
    },
    slideTextAltMin: {
        color: "#fff",
        fontFamily: "poppins-regular",
        fontSize: 13,
        lineHeight: 18,
        textAlign: "center",
    },
    containerForm: {
        padding: 20,
    },
    welcomeIntro: {
        marginTop: 30,
        marginBottom: 30,
    },
    slideFinal: {
        marginTop: 400,
        marginLeft: "15%",
        marginRight: "15%"
    },
    buttonMain: {
        backgroundColor: "#FFAD37",
        borderColor: "transparent",
        borderRadius: 5,
        color: "#004A8F",
        display: "flex",
        fontSize: 13,
        height: 48,
        justifyContent: "center",

        marginTop: 50,
    },
    buttonNoShow: {
        display: "none",
    },
    buttonText: {
        textAlign: "center",
        color: "#222222",
        fontWeight: "700",
        fontFamily: "poppins-semibold",
    },
    noDisplay: {
        display: "none",
    },
});
