import React, { Component } from "react";
import { createStackNavigator } from "react-navigation-stack";
import { createAppContainer, SafeAreaView } from "react-navigation";
import { Appbar, BottomNavigation, Text, List } from "react-native-paper";
import Start from "./Start";
import Login from "./Login/Login";
import Index from "./Index/Welcome";
import {
  StyleSheet,
  Image,
  Dimensions,
  View,
  Alert,
  Button,
  AsyncStorage,
  ScrollView,
  TouchableHighlight
} from "react-native";
import Signup from "./Register/SignUp";
import Instructions from "./Recover/Instructions";
import IndexEspecialidades from "./Especialidades/Index";
import * as Font from "expo-font";
import Stages from "./Tests/Stages";
import Casos from "./Tests/Casos";
import Resultados from "./Tests/Resultados";
import Ranking from "./Tests/Ranking";
import Suscription from "./Index/Suscription";
import Cupon from "./Index/Cupon";
import SuscriptionCupon from "./Index/Suscription-Cupon";
import {
  createDrawerNavigator,
  DrawerNavigatorItems,
} from "react-navigation-drawer";
import Header from "./Navigation/header";
import Svg, { Path, Rect } from "react-native-svg";
import { MaterialIcons } from "@expo/vector-icons";
import Profile from "./Register/Profile";
import { SafeAreaContext } from "react-native-safe-area-context";
import { log_out } from "./ArmFunctions";
import PasswordEdit from "./Register/PasswordEdit";
import RecoverSuccess from "./Recover/RecoverSuccess";
import PendingPayment from "./Index/PendingPayment";
import IndexImagenes from "./Imagenes/Index";
import IndexAleatorio from "./Aleatorio/Index";
import IndexIngles from "./Ingles/Index";
import IndexTemas from "./Temas/Index";
import IndexTipos from "./Tipos/Index";
import Preguntas from "./Tests/Preguntas";
import DrawerComponent from "./DrawerComponent";
import { useFonts } from 'expo-font';
/*

const AppNavigator = createDrawerNavigator
(
  {
    Start: { screen: Start },
    Login: { screen: Login },
    Index: { screen: Index },
    Signup: { screen: Signup },
    Instrucciones: { screen: Instructions },
    IndexEspecialidades: { screen: IndexEspecialidades },
    Stages: { screen: Stages },
    Casos: { screen: Casos },
    Suscription: { screen: Suscription },
    Cupon: { screen: Cupon },
    SuscriptionCupon: { screen : SuscriptionCupon }
  }
);

const Container = createAppContainer(AppNavigator);
*/

const StartScreens = {
  Start: {
    screen: Start,
    navigationOptions: {
      title: "Start",
      header: false,
    },
  },
  Login: {
    screen: Login,
    navigationOptions: {
      title: "Login",
      header: false,
    },
  },
  PendingPayment: {
    screen: PendingPayment,
    navigationOptions: {
      title: "Oxxo Pay",
      header: false,
    },
  },
  Index: {
    screen: Index,
    navigationOptions: {
      title: "Index",
      header: false,
    },
  },
  Signup: {
    screen: Signup,
    navigationOptions: {
      title: "Registro",
      header: false,
    },
  },
  Instrucciones: {
    screen: Instructions,
    navigationOptions: {
      title: "Recover",
      header: false,
    },
  },
  RecoverSuccess: {
    screen: RecoverSuccess,
    navigationOptions: {
      title: "Recover",
      header: false,
    },
  },
  Suscription: {
    screen: Suscription,
    navigationOptions: {
      title: "Suscription",
      header: false,
    },
  },
  Cupon: {
    screen: Cupon,
    navigationOptions: {
      title: "Cupon",
      header: false,
    },
  },
  SuscriptionCupon: {
    screen: SuscriptionCupon,
    navigationOptions: {
      title: "SuscriptionCupon",
      header: false,
    },
  },
};

const StartStack = createStackNavigator(StartScreens, {
  defaultNavigationOptions: {
    headerTintColor: "white",
    headerStyle: { backgroundColor: "#004A8F", height: 0 },
  },
});

class MyCustomHeaderBackImage extends React.Component<any, any> {
  render() {
    return (
      <Svg
        width="50"
        height="40"
        viewBox="0 0 50 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M50 0L50 40L-1.27162e-06 40L4.76837e-07 -2.18557e-06L50 0Z"
          fill="#004A8F"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.4142 19L30.707 14.7072L29.2928 13.293L22.5857 20.0001L29.2928 26.7072L30.707 25.293L26.414 21H35V19H26.4142Z"
          fill="white"
        />
      </Svg>
    );
  }
}

function MyCustomHeaderRank({ navigation }) {
  const openRanking = () => {
    navigation.navigate("Ranking");
  };
  return (
    <Svg
      width="50"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onPress={openRanking}
    >
      <Path
        d="M26.8 10H20.8L20 14C22.1 14 24.1 14.7 25.6 16L26.8 10ZM20 16C16.1 16 13 19.1 13 23C13 26.9 16.1 30 20 30C23.9 30 27 26.9 27 23C27 19.1 23.9 16 20 16ZM22.8 27.4L20 25.3L17.2 27.4L18.3 24.1L15.4 22H18.9L20 18.7L21.1 22H24.6L21.8 24.1L22.8 27.4ZM20 14L19.2 10H13.2L14.4 16C15.9 14.7 17.9 14 20 14Z"
        fill="white"
      />
    </Svg>
  );
}

function MyCustomDrawerImage({ props }) {
  return (
  
    <Svg 
      width="30" 
      height="34" 
      viewBox="0 0 30 34" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      style={styles.sidebarLogo}
      onPress={() => { props.navigation.navigate("IndexEspecialidades") }}
      >
      <Path 
        d="M24.8653 4.6355C21.4422 1.54517 17.0681 0 11.7433 0H0V14.1442C2.39303 12.7575 5.1664 11.9414 8.12995 11.9414V6.17274L21.7274 16.6799L8.12995 27.1949V20.0792C3.64501 20.0792 0 23.7242 0 28.2092V33.3756H11.7433C17.0681 33.3756 21.4422 31.8225 24.8653 28.7163C28.2884 25.6101 30 21.5927 30 16.664C30 11.7353 28.2884 7.72583 24.8653 4.6355Z" 
        fill="#FFAD37"/>
    </Svg>

  );
}

class MyCustomDrawerImageHidden extends React.Component<any, any> {
  render() {
    return (
      null
    );
  }
}

function MyCustomDrawerText({title}) {
  const [loaded] = useFonts({
    PoppinsRegular: require('../assets/fonts/Poppins-Regular.ttf'),
  });
  
  if (!loaded) {
    return null;
  }

  return (
    <View style={styles.sidebarItemLogout}>
        <Text
        style={{
          color: "#fff",
          fontSize: 14,
          fontWeight: "500",
          fontFamily:"poppins-regular"
        }}
      >
        {`${title}`}
      </Text>
      </View>
  );
}

class MyCustomDrawerTitle extends Component {
  componentDidMount(){
     Font.loadAsync({
      "poppins-semibold": require("../assets/fonts/Poppins-SemiBold.ttf"),
    });

    Font.loadAsync({
      "poppins-regular": require("../assets/fonts/Poppins-Regular.ttf"),
    });
    
    Font.loadAsync({
      "poppins-bold": require("../assets/fonts/Poppins-SemiBold.ttf"),
    });
  }
  render() {
    return (
        <Text
        style={{
          color: "#fff",
          fontFamily:"poppins-bold"
        }}
      >
        {`${this.props.title}`}
      </Text>    
    );
  }
}

function MyCustomMenuImage({ navigation }) {
  const openMenu = () => {
    navigation.openDrawer();
  };

  return (
    <TouchableHighlight
      onPress={openMenu}
    >
      <Svg
        width="50"
        height="40"
        viewBox="0 0 50 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M50 0L50 40L-1.27162e-06 40L4.76837e-07 -2.18557e-06L50 0Z"
          fill="transparent"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M35 21H24V19H35V21Z"
          fill="#666666"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19 14H39V16H19V14ZM19 19H39V21H19V19ZM39 24H19V26H39V24Z"
          fill="white"
        />
      </Svg>
    </TouchableHighlight>
  );
}

const AlumnoScreens = {
  IndexEspecialidades: {
    screen: IndexEspecialidades,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Módulos",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,
      };
    },
  },
  IndexTemas: {
    screen: IndexTemas,
    navigationOptions: ({ navigation }) => {
      return {
        title: `Temas de ${navigation.state.params.especialidad.name}`,
      };
    },
  },
  IndexTipos: {
    screen: IndexTipos,
    navigationOptions: ({ navigation }) => {
      return {
        title: `Tipos de test`,
      };
    },
  },
  Stages: {
    screen: Stages,
    navigationOptions: ({ navigation }) => ({
      title: `Test de ${navigation.state.params.especialidad.name}`,
    }),
  },
  Preguntas: {
    screen: Preguntas,
    navigationOptions: ({ navigation }) => ({
      title: `Pregunta`,
    }),
  },
  Casos: {
    screen: Casos,
    navigationOptions: ({ navigation }) => ({
      title: `Caso`,
    }),
  },
};

const AlumnoStack = createStackNavigator(AlumnoScreens, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#004A8F",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "poppins-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
    
  },
});

const InglesScreens = {
  IndexIngles: {
    screen: IndexIngles,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Ingles",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,
      };
    },
  },
  Stages: {
    screen: Stages,
    navigationOptions: ({ navigation }) => ({
      title: `Test de ${navigation.state.params.especialidad.name}`,
    }),
  },
  Casos: {
    screen: Casos,
    navigationOptions: ({ navigation }) => ({
      title: `Caso`,
    }),
  },
};

const InglesStack = createStackNavigator(InglesScreens, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#004A8F",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "poppins-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const AleatorioScreens = {
  AleatorioIndex: {
    screen: IndexAleatorio,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Aleatorio",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,
      };
    },
  },
  Stages: {
    screen: Stages,
    navigationOptions: ({ navigation }) => ({
      title: `Test de ${navigation.state.params.especialidad.name}`,
    }),
  },
  Casos: {
    screen: Casos,
    navigationOptions: ({ navigation }) => ({
      title: `Caso`,
    }),
  },
};

const AleatorioStack = createStackNavigator(AleatorioScreens, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#004A8F",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "poppins-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const ImagenesScreens = {
  ImagenesIndex: {
    screen: IndexImagenes,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Imagenes",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,
      };
    },
  },
  Stages: {
    screen: Stages,
    navigationOptions: ({ navigation }) => ({
      title: `Test de ${navigation.state.params.especialidad.name}`,
    }),
  },
  Casos: {
    screen: Casos,
    navigationOptions: ({ navigation }) => ({
      title: `Caso`,
    }),
  },
};

const ImagenesStack = createStackNavigator(ImagenesScreens, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#004A8F",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "poppins-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const ProfileScreen = {
  Profile: {
    screen: Profile,
    navigationOptions: ({ navigation }) => {
      return {
        title: <MyCustomDrawerTitle title={"Mi Perfil"}/>,
        headerLeft: <MyCustomMenuImage navigation={navigation} />,
      };
    },
  },
  PasswordEdit: {
    screen: PasswordEdit,
    navigationOptions: {
      title: <MyCustomDrawerTitle title={"Editar Contraseña"}/>,
    },
  },
};

const ProfileStack = createStackNavigator(ProfileScreen, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#004A8F",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "poppins-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const ResultadosScreen = {
  Resultados: {
    screen: Resultados,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Resultados",
        headerLeft: <MyCustomMenuImage navigation={navigation} />
      };
    },
  },
};

const ResultadosStack = createStackNavigator(ResultadosScreen, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#004A8F",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "poppins-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " "
  },
});

const RankingScreen = {
  Ranking: {
    screen: Ranking,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Ranking",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,

      };
    },
  },
};

const RankingStack = createStackNavigator(RankingScreen, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#004A8F",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "poppins-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const CustomDrawerComponent = (props) => (
  <SafeAreaView style={{ flex: 1 }}>
    <ScrollView style={styles.sidebar}>
      <DrawerItems {...props} />
    </ScrollView>
  </SafeAreaView>
);

const RootDrawerNavigator = createDrawerNavigator(
  {
    Start: {
      screen: StartStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerImageHidden />,
        drawerLockMode: "locked-closed",
        disabled: true
      },
    },
    IndexAleatorio: {
      screen: AleatorioStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerImageHidden />,
      },
    },
    IndexIngles: {
      screen: InglesStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerImageHidden />,
      },
    },
    IndexImagenes: {
      screen: ImagenesStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerImageHidden />,
      },
    },
    IndexEspecialidades: {
      screen: AlumnoStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerText title={'Módulos'}/>,
      },
    },
    Profile: {
      screen: ProfileStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerText title={'Cuenta'}/>,
      },
    },
    Ranking: {
      screen: RankingStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerText title={'Ranking'}/>,
      },
    },
    Resultados: {
      screen: ResultadosStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerText title={'Resultados'}/>,
      },
    },
  },
  {
    contentComponent: (props) => (
      <DrawerComponent props={props}/>
    ),
    drawerWidth: (Dimensions.get("window").width * 3) / 4,
    contentOptions: {
      activeTintColor: "#004A8F",
    },
    drawerBackgroundColor: "#004A8F",
  }
);

function drawerLabel(label) {
  return (
    <View style={styles.sidebarItem}>
      <Text style={styles.sidebarItemText}> {label} </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  sidebar: {
    padding: 40,
  },
  sidebarLogo: {
    marginBottom: 50,
    marginLeft: 40,
    marginTop: 50,
  },
  sidebarItem: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
  },
  sidebarItemLogout: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 45,
    color: "#fff",
  },
  sidebarItemAccordion: {
    paddingLeft: 35,
    marginBottom: -10
  },
  sidebarItemAccordionNest: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 35,
    fontSize: 14,
    marginBottom: -10,
    fontWeight: "500"
  },
  sidebarItemAccordionTitle: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "500"
  },
  sidebarItemText: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "500"
  },
  sidebarItemTextBold: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "500"
  },
  icon: {
    position: "absolute",
    left: -120,
    color: "#666",
  },
});

const NavigatorArm = createAppContainer(RootDrawerNavigator);
export default NavigatorArm;
