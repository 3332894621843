import React,{Component} from 'react'
import {
    StyleSheet,
    Image,
    Dimensions,
    View,
    Alert,
    Button,
    AsyncStorage,
    ScrollView,
    Platform,
  } from "react-native";
  import { createAppContainer, SafeAreaView } from "react-navigation";
  import { Appbar, BottomNavigation, Text, List } from "react-native-paper";
import { DrawerNavigatorItems } from 'react-navigation-drawer';
import Svg, { Path, Rect } from "react-native-svg";
import * as Font from "expo-font";
import { useFonts } from 'expo-font';


function MyCustomDrawerImage({ props }) {
    return (
    
      <Svg 
        width="30" 
        height="34" 
        viewBox="0 0 30 34" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        style={styles.sidebarLogo}
        onPress={() => { props.navigation.navigate("IndexEspecialidades") }}
        >
        <Path 
          d="M24.8653 4.6355C21.4422 1.54517 17.0681 0 11.7433 0H0V14.1442C2.39303 12.7575 5.1664 11.9414 8.12995 11.9414V6.17274L21.7274 16.6799L8.12995 27.1949V20.0792C3.64501 20.0792 0 23.7242 0 28.2092V33.3756H11.7433C17.0681 33.3756 21.4422 31.8225 24.8653 28.7163C28.2884 25.6101 30 21.5927 30 16.664C30 11.7353 28.2884 7.72583 24.8653 4.6355Z" 
          fill="#FFAD37"/>
      </Svg>
  
    );
  }

function DrawerComponent({props}) {
        const [loaded] = useFonts({
            PoppinsRegular: require('../assets/fonts/Poppins-Regular.ttf'),
        });
        
        if (!loaded) {
            return null;
        }

        return(
            <ScrollView style={{ flex: 1 }}>
        <SafeAreaView forceInset={{ top: "always", horizontal: "never" }}>
          <MyCustomDrawerImage props={props} />
          <List.Section>
            {/*
            <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Módulos" onPress={() => {
                props.navigation.closeDrawer();
                setTimeout(() => {
                  props.navigation.navigate("IndexEspecialidades");
                }, 200);
              }} />
              
              <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Aleatorio" onPress={() => {
                    props.navigation.closeDrawer();
                    setTimeout(() => {
                      props.navigation.navigate("IndexAleatorio");
                    }, 200);
                }} />
              <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Ingles" onPress={() => {
                props.navigation.closeDrawer();
                setTimeout(() => {
                  props.navigation.navigate("IndexIngles");
                }, 200);
              }} />
              <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Imagenes" onPress={() => {
                props.navigation.closeDrawer();
                setTimeout(() => {
                  props.navigation.navigate("IndexImagenes");
                }, 200);
              }} />*/}
          </List.Section>
          <DrawerNavigatorItems {...props} />
          
          
          <View style={styles.sidebarItemLogout}>
            <Text
              style={{
                color: "#fff",
                fontSize: 14,
                fontWeight: "500",
                fontFamily:"poppins-regular"
              }}
              onPress={() => {
                props.navigation.closeDrawer();
                setTimeout(() => {
                  if (Platform.OS === 'web') {
                    localStorage.clear();
                    window.location.replace('https://registro.directuni.mx/login');
                  } else {
                    props.navigation.navigate("Login");
                  }
                }, 200);
              }}
            >
              Cerrar sesión
      </Text>
          </View>
        </SafeAreaView>
      </ScrollView>

        );
}

const styles = StyleSheet.create({
    sidebar: {
      padding: 40,
    },
    sidebarLogo: {
      marginBottom: 50,
      marginLeft: 40,
      marginTop: 50,
    },
    sidebarItem: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 40,
    },
    sidebarItemLogout: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 45,
      color: "#fff",
    },
    sidebarItemAccordion: {
      paddingLeft: 35,
      marginBottom: -10
    },
    sidebarItemAccordionNest: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 35,
      fontSize: 14,
      marginBottom: -10,
      fontWeight: "500"
    },
    sidebarItemAccordionTitle: {
      color: "#fff",
      fontSize: 14,
      fontWeight: "500"
    },
    sidebarItemText: {
      color: "#fff",
      fontSize: 14,
      fontWeight: "500"
    },
    sidebarItemTextBold: {
      color: "#fff",
      fontSize: 14,
      fontWeight: "500"
    },
    icon: {
      position: "absolute",
      left: -120,
      color: "#666",
    },
  });

export default DrawerComponent;