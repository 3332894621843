import React, { Component } from "react";
import { Dimensions } from "react-native";
import {
   Image,
   Text,
   View,
   StyleSheet,
   SafeAreaView,
   TouchableOpacity,
   Alert,
   StatusBar,
} from "react-native";
import { TextInput, Button } from "react-native-paper";
import {
   getCupones,
   postValidateCupon,
   postSuscripcion,
   getCuponByCodigo,
} from "../ArmFunctions";
import { login } from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import Svg, { Path, Circle } from "react-native-svg";
import * as Font from "expo-font";
import { upperCase } from "lodash";

class Cupon extends Component {
   state = {
      codigo: "",
      token: "",
      user_id: "",
      cupon: [],
      plan: this.props.navigation.state.params.plan,
      cuponError: "",
   };

   componentDidMount() {
      Font.loadAsync({
         "poppins-semibold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
      });

      Font.loadAsync({
         "poppins-regular": require("../../assets/fonts/Poppins-Regular.ttf"),
      });
      AsyncStorage.getItem("user_token", (error, result) => {
         this.setState(
            {
               token: result,
            },
            () => {
               AsyncStorage.getItem("user_name", (error, result) => {
                  this.setState({
                     user_name: result,
                  });
               });
            }
         );
      });
   }

   cuponSuscription = () => {
      const codigo = this.state.codigo;
      const data = {
         codigo: codigo,
         token: this.state.token,
      };
      getCuponByCodigo(data).then((res) => {
         if (!res) {
            this.setState({
               cuponError: "Codigo invalido.",
            });
         } else {
            this.setState(
               {
                  cupon: res,
                  cuponError: "",
               },
               () => {
                  this.props.navigation.navigate("SuscriptionCupon", {
                     cupon: res,
                     plan: this.state.plan,
                     plan_id: this.state.plan.id,
                     planes: this.props.navigation.state.params.planes,
                  });
               }
            );
         }
      });
   };
   render() {
      var heightScreen = Dimensions.get("window").height;
      return (
         <>
            <StatusBar barStyle="light-content" backgroundColor="#004A8F" />
            {heightScreen > 780 ? (
               <View style={styles.topBarExtra}></View>
            ) : (
                  <View style={styles.topBarExtraMin}></View>
               )}
            <View style={styles.topBar}>
               
               <TouchableOpacity
            style={styles.backButton}
            onPress={() => this.props.navigation.navigate("Suscription")}
          >
            
              <Svg
              style={styles.backButtonIcon}
              width="50"
              height="40"
              viewBox="0 0 50 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                d="M50 0L50 40L-1.27162e-06 40L4.76837e-07 -2.18557e-06L50 0Z"
                fill="#004A8F"
              />
              <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.4142 19L30.707 14.7072L29.2928 13.293L22.5857 20.0001L29.2928 26.7072L30.707 25.293L26.414 21H35V19H26.4142Z"
                fill="white"
              />
            </Svg>
            
          </TouchableOpacity>
               <Text style={styles.headerTitle}>Cupón de descuento</Text>
               <TouchableOpacity style={styles.backButton}>
                  <Image
                     style={styles.backButtonIcon}
                     source={require("../../assets/backno.png")}
                  />
               </TouchableOpacity>
            </View>
            <View style={styles.container}>
               <Text style={styles.text}>
                  Introduce el código del cupon y obten tu descuento.
     </Text>
               <TextInput
                  label="Código"
                  placeholder="Código"
                  style={styles.inputText}
                  value={this.state.codigo}
                  onChangeText={(codigo) => this.setState({ codigo })}
                  theme={{
                     colors: { primary: "#000", placeholder: "#aaa" },
                     fonts: {
                        regular: {
                           fontFamily: "poppins-regular",
                           fontWeight: "normal",
                        },
                     },
                  }}
                  underlineColor="#E2E2E2"
                  autoCapitalize="characters"
               />
               <Text style={{ color: "red", fontFamily: "poppins-regular" }}>{this.state.cuponError}</Text>

               <TouchableOpacity
                  style={styles.buttonMain}
                  onPress={this.cuponSuscription}
               >
                  <Text style={styles.buttonText}>Aplicar Código</Text>
               </TouchableOpacity>
            </View>
         </>
      );
   }
}

const styles = StyleSheet.create({
   container: {
      flex: 1,
      backgroundColor: "white",
      padding: 20,
      paddingTop: 20,
   },
   text: {
      marginTop: 15,
      marginBottom: 15,
      fontFamily: "poppins-regular",
   },
   inputText: {
      backgroundColor: "white",
      height: 55,
      color: "#000",
      paddingLeft: 0,
      fontFamily: "poppins-regular",
      fontSize: 14,
      paddingHorizontal: 0,
      textTransform: "uppercase",
   },
   buttonMain: {
      backgroundColor: "#FFAD37",
      borderColor: "transparent",
      borderRadius: 5,
      color: "#fff",
      display: "flex",
      fontWeight: "500",
      fontSize: 13,
      height: 46,
      justifyContent: "center",
      marginTop: 30,
      width: "100%",
   },
   buttonText: {
      textAlign: "center",
      color: "black",
      fontWeight: "700",
      fontFamily: "poppins-semibold",
   },
   headerTitle: {
      fontSize: 18,
      fontWeight: "500",
      color: "#fff",
      fontFamily: "poppins-semibold",
   },
   backButtonIcon: {
      height: 20,
      width: 20,
   },
   topBar: {
      alignItems: "center",
      backgroundColor: "#004A8F",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      height: 45,
      paddingLeft: 20,
      paddingRight: 20,
   },
   topBarExtra: {
      backgroundColor: "#004A8F",
      height: 45,
   },
   topBarExtraMin: {
      backgroundColor: "#004A8F",
      height: 25,
   },
   startButtonText: {
      color: "#004A8F",
      fontSize: 13,
      fontWeight: "600",
      textAlign: "right",
   },
   errorText: {
      fontSize: 11,
      color: "#004A8F",
      fontFamily: "poppins-regular",
      marginTop: 5,
   },
});

export default Cupon;
