import React, { Component } from "react";
import { Dimensions } from "react-native";
import {
  Image,
  Text,
  View,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import { Button, TextInput } from "react-native-paper";
import { getCupones, getSuscription } from "../ArmFunctions";
import { login } from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import Svg, { Path, Circle } from "react-native-svg";
import * as Font from "expo-font";
import SpinnerButton from "react-native-spinner-button";
import * as SecureStore from 'expo-secure-store';
import * as WebBrowser from 'expo-web-browser';

class Login extends Component {
  state = {
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
  };
  validateFields = () => {
    let emailError = "";
    let passwordError = "";
    if (this.state.email == "") {
      emailError = "Correo no puede ser vacio.";
    }
    if (this.state.password == "") {
      passwordError = "Contraseña no puede ser vacia.";
    }
    if (emailError || passwordError) {
      this.setState({
        emailError,
        passwordError,
      });
      return false;
    } else {
      this.setState({
        emailError,
        passwordError,
      });
      return true;
    }
  };
  handleSession = () => {
    const data = {
      email: this.state.email,
      password: this.state.password,
    };

    const isValid = this.validateFields();
    this.setState({ loading: true });
    if (isValid) {
      login(data).then((res) => {
        if (res) {
          AsyncStorage.setItem("user_token", res.data.data.token);
          AsyncStorage.setItem("user_name", res.data.data.name);
          AsyncStorage.setItem("user_id", `${res.data.data.user_id}`);
          const data = {
            token: res.data.data.token,
          };
          const login_res = res;
          getSuscription(data).then((res) => {
            if (!res) {
              setTimeout(() => {
                WebBrowser.openBrowserAsync('https://registro.directuni.mx/');
                this.props.navigation.navigate("Start", { cupon: false }),
                  this.setState({ loading: false });
              }, 2000);
            } else {
              if (res.status) {
                const email = this.state.email;
                const password = this.state.password;

                SecureStore.setItemAsync('email', email)
                SecureStore.setItemAsync('password', password)
                
                if (login_res.data.data.login_count <= 4) {
                  setTimeout(() => {
                    const { navigate } = this.props.navigation;
                    navigate("Index"), this.setState({ loading: false });
                  }, 2000);
                } else {
                  setTimeout(() => {
                    const { navigate } = this.props.navigation;
                    navigate("IndexEspecialidades");
                    this.setState({ loading: false });
                  }, 2000);
                }
              } else {
                /*
                setTimeout(() => {
                  const { navigate } = this.props.navigation;
                  navigate("PendingPayment");
                  this.setState({ loading: false });
                }, 2000);
                */
                setTimeout(() => {
                  WebBrowser.openBrowserAsync('https://registro.directuni.mx/');
                  this.props.navigation.navigate("Start", { cupon: false }),
                   this.setState({ loading: false });
                 }, 1000);
              }
            }
          });
        } else {
          this.setState({ generalError: "Credenciales incorrectas." });
          setTimeout(() => {
            this.setState({ generalError: "", loading: false });
          }, 2000);
        }
      });
    } else {
      this.setState({ generalError: "Credenciales incorrectas." });
      setTimeout(() => {
        this.setState({ generalError: "", loading: false });
      }, 2000);
    }
  };

  read = async () => {
    try {
      const email = await SecureStore.getItemAsync('email');
      const password = await SecureStore.getItemAsync('password');
  
      //Alert.alert('value of credentials: ', `${email},${password}`);
  
      if (email && password) {
        this.setState({
          email,
          password
        },()=>{
          this.handleSession()
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount() {
    Font.loadAsync({
      "poppins-semibold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
    });

    Font.loadAsync({
      "poppins-regular": require("../../assets/fonts/Poppins-Regular.ttf"),
    });
    this.read();
  }
  render() {
    var heightScreen = Dimensions.get("window").height;
    return (
      <>
        <StatusBar barStyle="light-content" backgroundColor="#004A8F" />
        {heightScreen > 780 ? (
          <View style={styles.topBarExtra}></View>
        ) : (
            <View style={styles.topBarExtraMin}></View>
          )}
        <View style={styles.topBar}>
          <TouchableOpacity
            style={styles.backButton}
            onPress={() => this.props.navigation.navigate("Start")}
          >
            
              <Svg
              style={styles.backButtonIcon}
              width="50"
              height="40"
              viewBox="0 0 50 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                d="M50 0L50 40L-1.27162e-06 40L4.76837e-07 -2.18557e-06L50 0Z"
                fill="#004A8F"
              />
              <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.4142 19L30.707 14.7072L29.2928 13.293L22.5857 20.0001L29.2928 26.7072L30.707 25.293L26.414 21H35V19H26.4142Z"
                fill="white"
              />
            </Svg>
            
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Iniciar Sesión</Text>
          <TouchableOpacity style={styles.backButton}>
            <Image
              style={styles.backButtonIcon}
              source={require("../../assets/backno.png")}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.container}>
          <Text style={styles.errorText}>{this.state.generalError}</Text>
          <TextInput
            label="Correo Electrónico"
            placeholder="Correo Electrónico"
            autoCapitalize="none"
            style={styles.inputText}
            value={this.state.email}
            onChangeText={(email) => this.setState({ email })}
            theme={{
              colors: { primary: "#000", placeholder: "#aaa" },
              fonts: {
                regular: {
                  fontFamily: "poppins-regular",
                  fontWeight: "normal",
                },
              },
            }}
            underlineColor="#E2E2E2"
            keyboardType="email-address"
          />
          <Text style={styles.errorText}>{this.state.emailError}</Text>
          <TextInput
            label="Contraseña"
            placeholder="Contraseña"
            style={styles.inputText}
            value={this.state.password}
            onChangeText={(password) => this.setState({ password })}
            theme={{
              colors: { primary: "#000", placeholder: "#aaa" },
              fonts: {
                regular: {
                  fontFamily: "poppins-regular",
                  fontWeight: "normal",
                },
              },
            }}
            underlineColor="#E2E2E2"
            secureTextEntry={true}
          />
          <Text style={styles.errorText}>{this.state.passwordError}</Text>

          <SpinnerButton
            buttonStyle={styles.buttonMain}
            isLoading={this.state.loading}
            onPress={this.handleSession}
            indicatorCount={10}
          >
            <Text style={styles.buttonText}>Iniciar Sesión</Text>
          </SpinnerButton>
          <TouchableOpacity
            style={styles.buttonAlt}
            onPress={() => this.props.navigation.navigate("Instrucciones")}
          >
            <Text style={styles.buttonTextAlt}>Recuperar Contraseña</Text>
          </TouchableOpacity>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 20,
    paddingTop: 0,
  },
  inputText: {
    backgroundColor: "white",
    height: 55,
    color: "#000",
    paddingLeft: 0,
    fontFamily: "poppins-regular",
    fontSize: 14,
    paddingHorizontal: 0,
  },
  buttonMain: {
    backgroundColor: "#FFAD37",
    borderColor: "transparent",
    borderRadius: 5,
    color: "#000",
    display: "flex",
    fontWeight: "500",
    fontSize: 13,
    height: 46,
    justifyContent: "center",
    marginTop: 30,
    width: "100%",
    fontFamily: "poppins-semibold",
  },
  buttonAlt: {
    backgroundColor: "#fff",
    borderColor: "transparent",
    borderRadius: 5,
    color: "#000",
    display: "flex",
    fontWeight: "500",
    fontSize: 13,
    height: 46,
    justifyContent: "center",
    marginTop: 30,
    fontFamily: "poppins-semibold",
    width: "100%",
  },
  buttonText: {
    textAlign: "center",
    color: "black",
    fontWeight: "700",
    fontFamily: "poppins-semibold",
  },
  buttonTextAlt: {
    textAlign: "center",
    color: "#004A8F",
    fontWeight: "700",
    fontFamily: "poppins-semibold",
  },

  backButtonIcon: {
    height: 20,
    width: 20,
    marginLeft: -10
  },
  topBar: {
    alignItems: "center",
    backgroundColor: "#004A8F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 45,
    paddingLeft: 20,
    paddingRight: 20,
  },
  topBarExtra: {
    backgroundColor: "#004A8F",
    height: 45,
  },
  topBarExtraMin: {
    backgroundColor: "#004A8F",
    height: 25,
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: "500",
    color: "#fff",
    fontFamily: "poppins-semibold",
  },
  startButtonText: {
    color: "#004A8F",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "right",
  },
  startButtonRecover: {
    color: "black",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "center",
  },
  errorText: {
    fontSize: 11,
    color: "#004A8F",
    fontFamily: "poppins-regular",
    marginTop: 5,
  },
});

export default Login;
