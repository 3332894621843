import React, { Component } from "react";
import {
  Image,
  Text,
  View,
  StyleSheet,
  StatusBar,
  ScrollView,
  RefreshControl,
  Platform,
} from "react-native";
import { List, Avatar, ProgressBar, ActivityIndicator } from "react-native-paper";
import {
  getRanking,
} from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import Svg, { Path } from "react-native-svg";
import * as Font from "expo-font";
import { globalStyles } from "../Layout";

class Ranking extends Component {
  state = {
    user: [],
    users: [],
    refreshing: false,
    user_id: "",
    token: ""
  };

  loadRanking = () => {
    Font.loadAsync({
      "poppins-semibold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
    });

    Font.loadAsync({
      "poppins-regular": require("../../assets/fonts/Poppins-Regular.ttf"),
    });

    if (Platform.OS === 'web') {
      const token = localStorage.getItem('user_token');
      const user_name = localStorage.getItem('user_name');
      const user_id = localStorage.getItem('user_id');

      getRanking(token).then((res) => {
        const ranking = [];
        const users = res.length - 1;
        res.map((user, index) => {
          if (user.id == user_id) {
            user = {
              ...user,
              index: ((users - index) + 1)
            }
            ranking.unshift(user);
          }
          ranking.push(res[users - index]);

        });
        this.setState({
          users: ranking,
          refreshing : false,
          token,
          user_name
        });
      });
    } else {
      AsyncStorage.getItem("user_id", (error, result) => {
        this.setState({
          user_id: result
        })
      });
  
      AsyncStorage.getItem("user_token", (error, result) => {
        this.setState({
          token: result
        }, () => {
          getRanking(this.state.token).then((res) => {
            const ranking = [];
            const users = res.length - 1;
            res.map((user, index) => {
              if (user.id == this.state.user_id) {
                user = {
                  ...user,
                  index: ((users - index) + 1)
                }
                ranking.unshift(user);
              }
              ranking.push(res[users - index]);
  
            });
            this.setState({
              users: ranking,
              refreshing : false
            });
          });
        })
  
      });
    }
  };


  willFocus = this.props.navigation.addListener("willFocus", (payload) => {
    this.loadRanking();
  });


  render() {
    return (
      <View
        style={globalStyles.globalContainer}
      >
      <ScrollView
        style={Platform.OS === 'web' ? globalStyles.screenContainerDesktop : globalStyles.screenContainerMobile}
        refreshControl={
          <RefreshControl
            refreshing={this.state.refreshing}
            onRefresh={() => { this.loadRanking(this.state.token) }}
          />
        }
      >
        <StatusBar barStyle="light-content" backgroundColor="#004A8F" />
        <View style={styles.statsContainer}>
          <View style={styles.containerBottom}>
            <List.Section style={styles.especialidadesList}>
              {this.state.users.length > 0 ? this.state.users.map((user, index) =>
                parseFloat(user.promedio) > 0 ? (
                  <View key={index}>
                    <List.Item
                      title={user.name}
                      titleStyle={{
                        textTransform: "capitalize",
                        fontFamily: "poppins-regular",
                        fontSize: 14,
                        left: 15,
                      }}
                      style={styles.especialidadesListItem}
                      left={() =>
                        index == 1 ? (
                          <Svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={styles.badge}
                          >
                            <Path
                              d="M16.8 0H10.8L10 4C12.1 4 14.1 4.7 15.6 6L16.8 0ZM10 6C6.1 6 3 9.1 3 13C3 16.9 6.1 20 10 20C13.9 20 17 16.9 17 13C17 9.1 13.9 6 10 6ZM12.8 17.4L10 15.3L7.2 17.4L8.3 14.1L5.4 12H8.9L10 8.7L11.1 12H14.6L11.8 14.1L12.8 17.4ZM10 4L9.2 0H3.2L4.4 6C5.9 4.7 7.9 4 10 4Z"
                              fill="#F4B62C"
                            />
                          </Svg>
                        ) : index == 2 ? (
                          <Svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={styles.badge}
                          >
                            <Path
                              d="M16.8 0H10.8L10 4C12.1 4 14.1 4.7 15.6 6L16.8 0ZM10 6C6.1 6 3 9.1 3 13C3 16.9 6.1 20 10 20C13.9 20 17 16.9 17 13C17 9.1 13.9 6 10 6ZM12.8 17.4L10 15.3L7.2 17.4L8.3 14.1L5.4 12H8.9L10 8.7L11.1 12H14.6L11.8 14.1L12.8 17.4ZM10 4L9.2 0H3.2L4.4 6C5.9 4.7 7.9 4 10 4Z"
                              fill="#C1C1C1"
                            />
                          </Svg>
                        ) : index == 3 ? (
                          <Svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={styles.badge}
                          >
                            <Path
                              d="M16.8 0H10.8L10 4C12.1 4 14.1 4.7 15.6 6L16.8 0ZM10 6C6.1 6 3 9.1 3 13C3 16.9 6.1 20 10 20C13.9 20 17 16.9 17 13C17 9.1 13.9 6 10 6ZM12.8 17.4L10 15.3L7.2 17.4L8.3 14.1L5.4 12H8.9L10 8.7L11.1 12H14.6L11.8 14.1L12.8 17.4ZM10 4L9.2 0H3.2L4.4 6C5.9 4.7 7.9 4 10 4Z"
                              fill="#BE7949"
                            />
                          </Svg>
                        ) : (
                                <Avatar.Text
                                  style={{
                                    backgroundColor: "#fff",
                                    top: 0,
                                    fontFamily: "poppins-regular",
                                  }}
                                  color="#666"
                                  size={25}
                                  label={user.index ? user.index : index}
                                  labelStyle={{
                                    fontFamily: "poppins-regular",
                                  }}
                                />
                              )
                      }
                      right={() => (
                        <View style={styles.especialidadesListItemAction}>
                          <Text style={styles.especialidadesListPart}>
                            {parseFloat(user.promedio).toFixed(2)}
                          </Text>
                        </View>
                      )}
                    />
                    <ProgressBar
                      progress="100"
                      color="#f7f7f7"
                      style={styles.progressBarStyle}
                    />
                  </View>
                ) : null
              ) :
                <View style={styles.loading}>
                  <ActivityIndicator size="large" color="#212121" />
                </View>
              }
            </List.Section>
          </View>

          {/*<TouchableOpacity style={styles.buttonMain} onPress={()=>{ this.cleanProgress() }} hidden>
            <Text style={styles.buttonText}>Restablecer Resultados</Text>
          </TouchableOpacity>*/}
        </View>
      </ScrollView>
    </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  loading: {
    marginTop: 80,
    alignItems: 'center',
    justifyContent: 'center'
  },
  containerBottom: {
    padding: 25,
    paddingTop: 0,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  statsContainer: {
    display: "flex",
  },
  text: {
    marginTop: 15,
    marginBottom: 15,
  },
  inputText: {
    backgroundColor: "white",
    borderBottomColor: "#E2E2E2",
    borderBottomWidth: 1,
    height: 50,
    color: "#000",
    paddingLeft: 0,
  },
  buttonMain: {
    backgroundColor: "#F2F2F2",
    borderColor: "transparent",
    borderRadius: 5,
    color: "#fff",
    display: "flex",
    fontWeight: "500",
    height: 46,
    justifyContent: "center",
    marginTop: 30,
    width: "100%",
  },
  buttonText: {
    textAlign: "center",
    color: "black",
    fontWeight: "700",
    textTransform: "capitalize",
    letterSpacing: 0,
    fontFamily: "poppins-semibold",
    fontSize: 14,
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: "500",
    color: "#000",
    fontFamily: "poppins-semibold",
  },
  backButtonIcon: {
    height: 20,
    width: 20,
  },
  topBar: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 30,
    marginBottom: 20,
    marginTop: 10,
  },
  startButtonText: {
    color: "#004A8F",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "right",
  },
  stats: {
    backgroundColor: "#004A8F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 35,
    paddingLeft: 20,
    paddingRight: 20,
  },
  statsTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 22,
    lineHeight: 22,
    color: "#fff",
    margin: "auto",
    textAlign: "center",
    fontFamily: "poppins-semibold",
  },
  statsSubtitle: {
    fontWeight: "normal",
    fontSize: 11,
    lineHeight: 11,
    color: "#B8B8B8",
    textAlign: "center",
    marginTop: 12,
  },
  statsRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "33%",
  },
  statsCenter: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "33%",
  },
  dividerLine: {
    marginBottom: 30,
    marginTop: 30,
  },
  dividerLineTop: {
    marginBottom: 30,
  },
  especialidadesList: {
    paddingBottom: 50,
    paddingTop: 0,
    display: "flex",
    alignItems: "stretch",
  },
  especialidadesListSpec: {
    backgroundColor: "#F8BC47",
  },

  especialidadesListItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 50,
    fontFamily: "poppins-regular",
    fontSize: 14,
  },
  especialidadesListItemAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  especialidadesListPart: {
    fontFamily: "poppins-regular",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderColor: "white",
    color: "#666",
    top: 0,
    fontSize: 14,
  },
  progressBarStyle: {
    height: 1,
  },
  resultAdvice: {
    backgroundColor: "#ECECEC",
    borderRadius: 6,
    marginTop: 40,
    marginBottom: 40,
    padding: 20,
  },
  resultAdviceText: {
    color: "#425560",
    fontSize: 11,
  },
  doctorRow: {
    width: 60,
    height: 174,
  },
  doctorRowText: {
    fontFamily: "poppins-regular",
    fontSize: 14,
    lineHeight: 18,
    textAlign: "center",
    width: "60%",
    marginTop: 35,
  },
  statsTotal: {
    fontSize: 16,
    color: "#fff",
    fontFamily: "poppins-semibold",
  },
  resultRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  resultRowText: {
    fontSize: 11,
    lineHeight: 14,
    color: "#666",
  },
  badge: {
    marginTop: 3,
  },
});

export default Ranking;
