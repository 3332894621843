import React, { Component } from "react";
import * as Font from "expo-font";
import { AsyncStorage, Alert, RefreshControl, Platform } from "react-native";
import {
  Image,
  Text,
  TextInput,
  View,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import {
  getEspecialidades,
  getCasos,
  getNiveles,
  getUserNivel,
  PostUserTest,
  postTest,
  getNivelesEspecialidad,
  postTestTema,
  getUserNivelTemaTipo,
  getUserStages,
} from "../ArmFunctions";
import { List, Avatar, Button, ProgressBar } from "react-native-paper";
import Svg, { Path } from "react-native-svg";
import checkStage from "../../assets/checkStage.png";
import Spinner from 'react-native-loading-spinner-overlay';
import { globalStyles } from "../Layout";

class Stages extends Component {
  state = {
    niveles: [],
    especialidad: this.props.navigation.state.params.especialidad,
    tipo: this.props.navigation.state.params.tipo,
    tema: this.props.navigation.state.params.tema,
    user_stages: [],
    user_name: "",
    token: "",
    casos: [],
    refreshing: false,
    answered_stage_1: false,
    progress_stage_1: 0,
    progress_stage_2: 0,
    progress_stage_3: 0,
    spinner : false
  };

  componentDidMount() {
    this.onRefresh();
    this.props.navigation.addListener("willFocus", this._handleStateChange);
    //Alert.alert(`${this.state.tipo.id}`)
  }

  _handleStateChange = (state) => {
    this.onRefresh();
  };

  onRefresh = () => {
    this.setState({ refreshing: true });
    if (Platform.OS === 'web') {
      const token = localStorage.getItem('user_token');
      const user_name = localStorage.getItem('user_name');

      const data = {
        token: token,
        tema_id : this.state.tema.id,
        tipo_id : this.state.tipo.id
      };
      getUserStages(data).then((res) => {
        if (!res) {
          this.setState({
            niveles: this.state.especialidad.niveles,
          });
        } else {
          const niveles = res;
          let parent_answered_count = 0;
          let parent_levels = [];
          let no_domino_levels = [];
          let no_domino_levels_count = 0;
          let child_levels = [];
          let child_levels_count = 0;
          let answered_stage_1 = false;
          let answered_stage_2 = false;
          let answered_stage_3 = false;
          let progress_stage_1 = 0;
          let progress_stage_2 = 0;
          let progress_stage_3 = 0;
          niveles.map((nivel, index) => {
            if (nivel.name !== "no lo domino") {
              nivel.nivel_usuario.status
                ? (parent_answered_count =
                  parent_answered_count + 1 && parent_levels.push(nivel))
                : null;
            }
            if (nivel.parent_id !== null && nivel.name !== "no lo domino") {
              nivel.nivel_usuario.status
                ? (child_levels_count =
                  child_levels_count + 1 && child_levels.push(nivel))
                : null;
            }
            if (nivel.parent_id == null && nivel.name == "no lo domino") {
              nivel.nivel_usuario.status
                ? (no_domino_levels_count =
                  no_domino_levels_count + 1 &&
                  no_domino_levels.push(nivel))
                : null;
            }
          });
          if (
            parent_answered_count >= parent_levels.length &&
            parent_answered_count > 0
          ) {
            answered_stage_1 = true;
            progress_stage_1 = (
              parent_answered_count / parent_levels.length
            ).toFixed(2);
          }
          if (
            no_domino_levels_count >= no_domino_levels.length &&
            no_domino_levels_count > 0
          ) {
            answered_stage_2 = true;
            progress_stage_2 = (
              no_domino_levels_count / no_domino_levels.length
            ).toFixed(2);
          }
          if (
            child_levels_count >= child_levels.length &&
            child_levels_count > 0
          ) {
            answered_stage_3 = true;
            progress_stage_3 = (
              child_levels_count / child_levels.length
            ).toFixed(2);
          }

          this.setState({
            token,
            user_name,
            niveles: niveles,
            answered_stage_1,
            progress_stage_1,
            answered_stage_2,
            progress_stage_2,
            answered_stage_3,
            progress_stage_3,
          });
        }
      });
    } else {
    AsyncStorage.getItem("user_token", (error, result) => {
      this.setState(
        {
          token: result,
        },
        () => {
          const data = {
            token: this.state.token,
            tema_id : this.state.tema.id,
            tipo_id : this.state.tipo.id
          };
          getUserStages(data).then((res) => {
            if (!res) {
              this.setState({
                niveles: this.state.especialidad.niveles,
              });
            } else {
              const niveles = res;
              let parent_answered_count = 0;
              let parent_levels = [];
              let no_domino_levels = [];
              let no_domino_levels_count = 0;
              let child_levels = [];
              let child_levels_count = 0;
              let answered_stage_1 = false;
              let answered_stage_2 = false;
              let answered_stage_3 = false;
              let progress_stage_1 = 0;
              let progress_stage_2 = 0;
              let progress_stage_3 = 0;
              niveles.map((nivel, index) => {
                if (nivel.name !== "no lo domino") {
                  nivel.nivel_usuario.status
                    ? (parent_answered_count =
                      parent_answered_count + 1 && parent_levels.push(nivel))
                    : null;
                }
                if (nivel.parent_id !== null && nivel.name !== "no lo domino") {
                  nivel.nivel_usuario.status
                    ? (child_levels_count =
                      child_levels_count + 1 && child_levels.push(nivel))
                    : null;
                }
                if (nivel.parent_id == null && nivel.name == "no lo domino") {
                  nivel.nivel_usuario.status
                    ? (no_domino_levels_count =
                      no_domino_levels_count + 1 &&
                      no_domino_levels.push(nivel))
                    : null;
                }
              });
              if (
                parent_answered_count >= parent_levels.length &&
                parent_answered_count > 0
              ) {
                answered_stage_1 = true;
                progress_stage_1 = (
                  parent_answered_count / parent_levels.length
                ).toFixed(2);
              }
              if (
                no_domino_levels_count >= no_domino_levels.length &&
                no_domino_levels_count > 0
              ) {
                answered_stage_2 = true;
                progress_stage_2 = (
                  no_domino_levels_count / no_domino_levels.length
                ).toFixed(2);
              }
              if (
                child_levels_count >= child_levels.length &&
                child_levels_count > 0
              ) {
                answered_stage_3 = true;
                progress_stage_3 = (
                  child_levels_count / child_levels.length
                ).toFixed(2);
              }

              this.setState({
                niveles: niveles,
                answered_stage_1,
                progress_stage_1,
                answered_stage_2,
                progress_stage_2,
                answered_stage_3,
                progress_stage_3,
              });
            }
          });
          AsyncStorage.getItem("user_name", (error, result) => {
            this.setState({
              user_name: result,
            });
          });
        }
      );
    });

  }

    Font.loadAsync({
      "poppins-semibold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
    });

    Font.loadAsync({
      "poppins-regular": require("../../assets/fonts/Poppins-Regular.ttf"),
    });
    setTimeout(() => {
      this.setState({ refreshing: false });
    }, 2000);
  };

  getTestContent = (nivel) => {
    const data = {
      token: this.state.token,
      nivel_id: nivel.id,
      especialidad_id: parseInt(this.state.especialidad.id),
      tema_id : this.state.tema.id,
      tipo_id : this.state.tipo.id
    };
    this.setState({
      spinner : true
    })
    //console.log(data,'data for request');
    if(this.state.tipo.id == 2){
      getUserNivelTemaTipo(data).then(res => {
        if(!res){
          postTestTema(data).then(res =>{
            if (!res) {
              Alert.alert(
                "No hay suficientes preguntas para iniciar el test."
              );
              this.setState({
                spinner : false
              })
            } else {
              getUserNivelTemaTipo(data).then((res) => {
                if(res){
                  this.props.navigation.navigate("Preguntas", {
                    test_content: res,
                  });
                  this.setState({
                    spinner : false
                  })
                }else{
                  Alert.alert('Error al iniciar el test')
                  this.setState({
                    spinner : false
                  })
                }
              });
            }
          })
        }else{
          if(res.preguntas_id == ""){
            Alert.alert("No hay preguntas en esta seccion.");
            this.setState({
              spinner : false
            })
          }else{
            this.props.navigation.navigate("Preguntas", {
              test_content: res,
            });
            this.setState({
              spinner : false
            })
          }
        }
      })
    }else{
      getUserNivelTemaTipo(data).then((res) => {
        if (!res) {
            postTestTema(
              data
            ).then((res) => {
              if (!res) {
                Alert.alert(
                  "No hay suficientes casos para iniciar el test."
                );
                this.setState({
                  spinner : false
                })
              } else {
                const niveles = res;
                const nivel_id = nivel.id;
                niveles.map((nivel) => {
                  if (nivel.id == nivel_id) {
                    getUserNivelTemaTipo(data).then((res) => {
                      this.props.navigation.navigate("Casos", {
                        test_content: res,
                      });
                      this.setState({
                        spinner : false
                      })
                    });
                  }
                });
              }
            });
        } else {
            if(res.casos_id == ""){
              Alert.alert("No hay casos en esta seccion.")
              this.setState({
                spinner : false
              })
            }else{
              this.props.navigation.navigate("Casos", {
                test_content: res,
              });
              this.setState({
                spinner : false
              })
            }
        }
      });
    }
  }

  render() {
    return (
      <View
      style={globalStyles.globalContainer}
    >
      <ScrollView
        style={Platform.OS === 'web' ? globalStyles.screenContainerDesktop : globalStyles.screenContainerMobile}
        refreshControl={
          <RefreshControl
            refreshing={this.state.refreshing}
            onRefresh={this.onRefresh}
          />
        }
      >
        <Spinner
          visible={this.state.spinner}
          textContent={'Cargando...'}
          textStyle={styles.spinnerTextStyle}
        />
        <Text style={styles.headerSubtitle}>
          {this.state.answered_stage_1 ? (
            <Text>
              Etapa 1 - <Text style={styles.headerSubtitleAlt}>Completada</Text>
            </Text>
          ) : (
              "Etapa 1"
            )}
        </Text>

        <ProgressBar
          progress={this.state.progress_stage_1}
          color="#004A8F"
          color={
            this.state.answered_stage_1
              ? "#ACBED0"
              : this.state.especialidad.hexa_color

          }
          style={styles.progressBarStyle}
        />

        <List.Section style={styles.especialidadesList}>
          {this.state.niveles.map((nivel) => {
            if (nivel.name !== "no lo domino" && nivel.parent_id == null) {
              return (
                <List.Item
                  title={nivel.name}
                  titleStyle={{
                    textTransform: "capitalize",
                    fontFamily: "poppins-regular",
                  }}
                  key={nivel.id}
                  style={styles.especialidadesListItem}
                  right={() => (
                    <View style={styles.especialidadesListItemAction}>
                      <Text style={styles.especialidadesListPart} size={24}>
                        {nivel.nivel_usuario ? (
                          nivel.nivel_usuario.status ? (
                            
                            <View style={{width: 20, height: 20}}>
                              <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <Path d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" fill="#339BD6"/>
                              <Path fill-rule="evenodd" clip-rule="evenodd" d="M14.7071 7.70718L8.46542 13.9489L5.30127 10.8583L6.69875 9.42756L8.44888 11.137L13.2929 6.29297L14.7071 7.70718Z" fill="white"/>
                              </Svg>
                            </View>
                            
                          ) : nivel.asignados ? (
                            nivel.asignados + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                          ) : (
                                nivel.cantidad_casos + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                              )
                        ) : nivel.asignados ? (
                          nivel.asignados + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                        ) : (
                              nivel.cantidad_casos + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                            )}
                      </Text>
                      <Image
                        style={styles.backButtonIcon}
                        source={require("../../assets/next-arrow.png")}
                      />
                    </View>
                  )}
                  onPress={() => {
                    this.getTestContent(nivel)
                  }}
                />
              );
            }
          })}
        </List.Section>

        {this.state.answered_stage_1 ? null : (
          <View style={styles.blockedAdvice}>
            <Text style={styles.blockedAdviceText}>
              Completa la Etapa 1 para continuar con la siguiente etapa.
            </Text>
          </View>
        )}
        <View
          style={this.state.answered_stage_1 ? styles.unblockedContainer : styles.blockedContainer}
          pointerEvents={this.state.answered_stage_1 ? "auto" : "none"}
        >
          <Text style={styles.headerSubtitle}>
            {this.state.answered_stage_2 ? (
              <Text>
                Etapa 2 -{" "}
                <Text style={styles.headerSubtitleAlt}>Completada</Text>
              </Text>
            ) : (
                "Etapa 2"
              )}
          </Text>

          <ProgressBar
            progress={this.state.progress_stage_2}
            color="#004A8F"
            color={
              this.state.answered_stage_2
                ? "#ACBED0"
                : this.state.especialidad.hexa_color
            }
            style={styles.progressBarStyle}
          />

          <List.Section style={styles.especialidadesList}>
            {this.state.niveles.map((nivel) => {
              if (nivel.name == "no lo domino" && nivel.parent_id == null) {
                return (
                  <List.Item
                    title={nivel.name}
                    titleStyle={{
                      textTransform: "capitalize",
                      fontFamily: "poppins-regular",
                    }}
                    key={nivel.id}
                    style={styles.especialidadesListItem}
                    right={() => (
                      <View style={styles.especialidadesListItemAction}>
                        <Text style={styles.especialidadesListPart} size={24}>
                          {nivel.nivel_usuario ? (
                            nivel.nivel_usuario.status ? (
                              <View style={{width: 20, height: 20}}>
                              <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <Path d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" fill="#339BD6"/>
                              <Path fill-rule="evenodd" clip-rule="evenodd" d="M14.7071 7.70718L8.46542 13.9489L5.30127 10.8583L6.69875 9.42756L8.44888 11.137L13.2929 6.29297L14.7071 7.70718Z" fill="white"/>
                              </Svg>
                            </View>
                            ) : nivel.asignados ? (
                              nivel.asignados + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                            ) : (
                                  nivel.cantidad_casos + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                                )
                          ) : nivel.asignados ? (
                            nivel.asignados + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                          ) : (
                                nivel.cantidad_casos + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                              )}
                        </Text>
                        <Image
                          style={styles.backButtonIcon}
                          source={require("../../assets/next-arrow.png")}
                        />
                      </View>
                    )}
                    onPress={() => {
                      this.getTestContent(nivel)
                    }}
                  />
                );
              }
            })}
          </List.Section>
        </View>

        {this.state.answered_stage_2 ? null : (
          <View style={styles.blockedAdvice}>
            <Text style={styles.blockedAdviceText}>
              Completa la Etapa 2 para continuar con la siguiente etapa.
            </Text>
          </View>
        )}

        <View
          style={this.state.answered_stage_2 ? null : styles.blockedContainer}
          pointerEvents={this.state.answered_stage_2 ? "auto" : "none"}
        >
          <Text style={styles.headerSubtitle}>
            {this.state.answered_stage_3 ? (
              <Text>
                Etapa 3 -{" "}
                <Text style={styles.headerSubtitleAlt}>Completada</Text>
              </Text>
            ) : (
                "Etapa 3"
              )}
          </Text>

          <ProgressBar
            progress={this.state.progress_stage_3}
            color="#004A8F"
            color={
              this.state.answered_stage_3
                ? "#ACBED0"
                : this.state.especialidad.hexa_color
            }
            style={styles.progressBarStyle}
          />

          <List.Section style={styles.especialidadesList}>
            {this.state.niveles.map((nivel) => {
              if (nivel.name !== "no lo domino" && nivel.parent_id !== null) {
                return (
                  <List.Item
                    title={nivel.name}
                    titleStyle={{
                      textTransform: "capitalize",
                      fontFamily: "poppins-regular",
                    }}
                    key={nivel.id}
                    style={styles.especialidadesListItem}
                    right={() => (
                      <View style={styles.especialidadesListItemAction}>
                        <Text style={styles.especialidadesListPart} size={24}>
                          {nivel.nivel_usuario ? (
                            nivel.nivel_usuario.status ? (
                              <Image
                                source={checkStage}
                                style={styles.checkStage}
                              />
                            ) : nivel.asignados ? (
                              nivel.asignados + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                            ) : (
                                  nivel.cantidad_casos + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                                )
                          ) : nivel.asignados ? (
                            nivel.asignados + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                          ) : (
                                nivel.cantidad_casos + ( this.state.tipo.id  == 2 ? " Preguntas": " Casos")
                              )}
                        </Text>
                        <Image
                          style={styles.backButtonIcon}
                          source={require("../../assets/next-arrow.png")}
                        />
                      </View>
                    )}
                    onPress={() => {
                      this.getTestContent(nivel)
                    }}
                  />
                );
              }
            })}
          </List.Section>
        </View>
      </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 20,
  },
  inputText: {
    backgroundColor: "white",
    borderBottomColor: "#E2E2E2",
    borderBottomWidth: 1,
    height: 50,
    color: "#000",
    paddingLeft: 0,
  },
  progressBarStyle: {
    height: 2,
  },
  buttonMain: {
    backgroundColor: "#FFAD37",
    borderColor: "transparent",
    borderRadius: 5,
    color: "#fff",
    display: "flex",
    fontWeight: "500",
    fontSize: 13,
    height: 46,
    justifyContent: "center",
    marginTop: 30,
    width: "100%",
  },
  buttonText: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: "500",
    color: "#000",
    fontFamily: "poppins-semibold",
  },
  headerSubtitle: {
    fontSize: 14,
    color: "#666",
    fontFamily: "poppins-regular",
    marginBottom: 15,
    marginTop: 25,
  },
  headerSubtitleAlt: {
    color: "#23B85F",
    fontFamily: "poppins-semibold",
  },
  backButtonIcon: {
    height: 20,
    width: 20,
  },
  topBar: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 20,
    marginBottom: 20,
    marginTop: 10,
  },
  subBar: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 30,
    marginTop: 10,
  },
  startButtonText: {
    color: "#004A8F",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "right",
  },
  startButtonRecover: {
    color: "black",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "center",
  },
  especialidadesList: {
    marginBottom: 40,
    marginTop: 15,
    padding: 0,
  },
  blockedContainer: {
    opacity: 0.5,
  },
  especialidadesListSpec: {
    backgroundColor: "#F8BC47",
  },
  especialidadesListPart: {
    fontFamily: "poppins-semibold",
    display: "flex",
    alignItems: "center",
    color: "#222",
    marginRight: 10,
    paddingBottom : 6
  },
  especialidadesListItem: {
    borderBottomColor: "#E2E2E2",
    borderBottomWidth: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 80,
    paddingLeft: 0,
    textTransform: "capitalize",
  },
  especialidadesListItemAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerProgressText: {
    color: "#222222",
    fontSize: 12,
  },
  blockedAdvice: {
    backgroundColor: "#FFAD3735",
    borderRadius: 5,
    marginBottom: 40,
    padding: 20,
  },
  blockedAdviceText: {
    color: "#222222",
    fontFamily: "poppins-regular",
    textAlign: "center",
  },
  checkStage: {
    height: 20,
    width: 20,
  },
  spinnerTextStyle: {
    color: '#FFFFFF',
    fontFamily: "poppins-regular",
  },
});

export default Stages;

