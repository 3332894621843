import React, { Component } from "react";
import {
    Image,
    Text,
    View,
    StyleSheet,
    SafeAreaView,
    TouchableOpacity,
    Alert,
} from "react-native";
import { Button, TextInput } from "react-native-paper";
import {
    getCupones,
    postValidateCupon,
    postSuscripcion,
    getCuponByCodigo,
    postPasswordEdit,
} from "../ArmFunctions";
import { login } from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import Svg, { Path, Circle } from "react-native-svg";
import * as Font from "expo-font";

class PasswordEdit extends Component {
    state = {
        token: "",
        user_id: "",
        password: "",
        n_password: "",
        cuponError: "",
    };

    componentDidMount() {
        Font.loadAsync({
            "poppins-semibold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
        });
        Font.loadAsync({
            "poppins-regular": require("../../assets/fonts/Poppins-Regular.ttf"),
        });
        AsyncStorage.getItem("user_token", (error, result) => {
            this.setState({
                token: result,
            });
        });
    }

    validateFields = () => {
        if (this.state.password == "" || this.state.n_password == "") {
            Alert.alert("", "Los campos no pueden ser vacios.");
        } else if (
            this.state.password.length < 8 ||
            this.state.n_password.length < 8
        ) {
            Alert.alert("", "La contraseña no puede tener menos de 8 caracteres.");
        } else {
            return true;
        }
    };

    changePassword = () => {
        const data = {
            oldPassword: this.state.password,
            newPassword: this.state.n_password,
            token: this.state.token,
        };
        const isValid = this.validateFields();
        if (isValid) {
            postPasswordEdit(data).then((res) => {
                if (res) {
                    Alert.alert("", "Contraseña actualizada con exito.");
                    //this.props.navigation.navigate("Login");
                } else {
                    Alert.alert("", "Datos incorrectos.");
                }
            });
        }
    };
    render() {
        return (
            <View style={styles.container}>
                <Text style={styles.text}>
                    Introduce tu antigua contraseña, seguida de la nueva.
    </Text>
                <TextInput
                    label="Contraseña anterior"
                    placeholder="Contraseña anterior"
                    style={styles.inputText}
                    value={this.state.password}
                    onChangeText={(password) => this.setState({ password })}
                    theme={{
                        colors: { primary: "#000", placeholder: "#aaa" },
                        fonts: {
                            regular: {
                                fontFamily: "poppins-regular",
                                fontWeight: "normal",
                            },
                        },
                    }}
                    underlineColor="#E2E2E2"
                    secureTextEntry={true}
                />
                <Text style={{ color: "red" }}>{this.state.passwordError}</Text>
                <TextInput
                    label="Contraseña nueva"
                    placeholder="Contraseña nueva"
                    style={styles.inputText}
                    value={this.state.c_password}
                    onChangeText={(n_password) => this.setState({ n_password })}
                    theme={{
                        colors: { primary: "#000", placeholder: "#aaa" },
                        fonts: {
                            regular: {
                                fontFamily: "poppins-regular",
                                fontWeight: "normal",
                            },
                        },
                    }}
                    underlineColor="#E2E2E2"
                    secureTextEntry={true}
                />

                <Text style={{ color: "red" }}>{this.state.c_passwordError}</Text>

                <TouchableOpacity style={styles.buttonMain} onPress={this.changePassword}>
                    <Text style={styles.buttonText}>Confirmar</Text>
                </TouchableOpacity>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
        padding: 20,
        paddingTop: 0,
    },
    text: {
        marginTop: 25,
        marginBottom: 15,
        fontFamily: "poppins-regular",
    },
    inputText: {
        backgroundColor: "white",
        height: 55,
        color: "#000",
        paddingLeft: 0,
        fontFamily: "poppins-regular",
        fontSize: 14,
        paddingHorizontal: 0,
    },
    buttonMain: {
        backgroundColor: "#FFAD37",
        borderColor: "transparent",
        borderRadius: 5,
        color: "#fff",
        display: "flex",
        fontWeight: "500",
        fontSize: 13,
        height: 46,
        justifyContent: "center",
        marginTop: 30,
        width: "100%",
    },
    buttonText: {
        textAlign: "center",
        color: "black",
        fontWeight: "700",
        fontFamily: "poppins-semibold",
    },
    headerTitle: {
        fontSize: 18,
        fontWeight: "500",
        color: "#000",
        fontFamily: "poppins-semibold",
    },
    backButtonIcon: {
        height: 20,
        width: 20,
    },
    topBar: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: 30,
        marginBottom: 20,
        marginTop: 10,
    },
    startButtonText: {
        color: "#004A8F",
        fontSize: 13,
        fontWeight: "600",
        textAlign: "right",
    },
});

export default PasswordEdit;
