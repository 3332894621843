import React, { Component } from "react";
import * as Font from "expo-font";
import { AsyncStorage, Alert, Platform } from "react-native";
import {
  Image,
  Text,
  TextInput,
  View,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
  TouchableHighlight,
  StatusBar,
  RefreshControl,
} from "react-native";
import { getEspecialidades, getCasos, getUserNivel } from "../ArmFunctions";
import { List, Avatar, ProgressBar } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";
import { globalStyles } from "../Layout";

class IndexEspecialidades extends Component {
  state = {
    especialidades: [],
    token: "",
    casos: [],
    refreshing: false,
  };

  componentDidMount() {
    this.onRefresh();
    this.props.navigation.addListener("willFocus", this._handleStateChange);
  }

  _handleStateChange = (state) => {
    this.onRefresh();
  };

  casosLength = (especialidad_id) => { };

  onRefresh = () => {
    if (Platform.OS === 'web') {
      const user_token = localStorage.getItem('user_token');
  
      // console.log(user_token, 'test user token');
      if (user_token && user_token !== 'null') {
        const user_name = localStorage.getItem('user_name');
        getEspecialidades(user_token).then((res) => {
          const especialidades = res.filter(especialidad => especialidad.tipo_id == 1);
          this.setState({
          especialidades: especialidades,
          user_token,
          user_name
          });
        });
        setTimeout(() => {
          this.setState({ refreshing: false });
        }, 2000);
      } else {
        const queryString = window.location.search;
        if (!queryString) {
          window.location.replace('https://registro.directuni.mx/login');
        } else {
          const params = new URLSearchParams(queryString);
          const user_token = params.get('token');
          const user_id = params.get('user_id');
          const user_name = params.get('user_name');
          localStorage.setItem('user_token', user_token);
          localStorage.setItem('user_name', user_name);
          localStorage.setItem('user_id', user_id);
          window.location.assign('/');
        }
      }
    } else {
      AsyncStorage.getItem("user_token", (error, result) => {
        if (result == null) {
         this.props.navigation.navigate("Login");
        } else {
         this.setState(
          {
           token: result,
          },
          () => {
           AsyncStorage.getItem("user_name", (error, result) => {
            this.setState({
             user_name: result,
            });
           });
           getEspecialidades(this.state.token).then((res) => {
            const especialidades = res.filter(especialidad => especialidad.tipo_id == 1);
            this.setState({
             especialidades: especialidades,
            });
           });
           setTimeout(() => {
            this.setState({ refreshing: false });
           }, 2000);
          }
         );
        }
       });
    }

    Font.loadAsync({
      "poppins-semibold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
    });

    Font.loadAsync({
      "poppins-regular": require("../../assets/fonts/Poppins-Regular.ttf"),
    });
  };

  willFocus = this.props.navigation.addListener("willFocus", (payload) => {
    this.onRefresh();
  });

  render() {
    return (
    <View
      style={globalStyles.globalContainer}
    >
      <ScrollView
        style={Platform.OS === 'web' ? globalStyles.screenContainerDesktop : globalStyles.screenContainerMobile}
        refreshControl={
          <RefreshControl
            refreshing={this.state.refreshing}
            onRefresh={this.onRefresh}
          />
        }
      >
        <StatusBar barStyle="light-content" backgroundColor="#004A8F" />
        <List.Section style={styles.especialidadesList}>
          {this.state.especialidades.map((especialidad) => (
            <View key={especialidad.id}>
              <List.Item
                title={especialidad.name}
                titleStyle={{
                  textTransform: "capitalize",
                  fontFamily: "poppins-regular",
                  left: 15,
                }}
                key={especialidad.id}
                style={styles.especialidadesListItem}
                left={() => (
                  <Avatar.Text
                    style={{
                      backgroundColor: especialidad.hexa_color,
                      //backgroundColor: '#FFAD37',
                      top: 0,
                      fontFamily: "poppins-regular",
                    }}
                    color="#fff"
                    size={36}
                    label={especialidad.name.charAt(0)}
                    labelStyle={{
                      fontFamily: "poppins-semibold",
                    }}
                  />
                )}
                right={() => (
                  <View style={styles.especialidadesListItemAction}>
                    {/*<Text style={styles.especialidadesListPart}>
                      {especialidad.progress.etapa_actual}
                    </Text>

                    <Text style={styles.especialidadesListPart}>
                      {especialidad.progress.casos_terminados +
                        "/" +
                        especialidad.cant_casos}
                      </Text>*/}
                    <Image
                      style={styles.backButtonIcon}
                      source={require("../../assets/next-arrow.png")}
                    />
                  </View>
                )}
                onPress={() => {
                  this.props.navigation.navigate("IndexTemas", {
                    //stages: especialidad.niveles,
                    especialidad: especialidad,
                    token : this.state.token
                  });
                }}
              />
              <ProgressBar
                progress={
                  especialidad.progress.casos_terminados / especialidad.cant_casos
                }
                color={especialidad.hexa_color}
                //color={'#004A8F'}
                style={styles.progressBarStyle}
              />
            </View>
          ))}
        </List.Section>
      </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 20,
  },
  progressBarStyle: {
    marginLeft: "17%",
    width: "83%",
    height: 2,
  },
  inputText: {
    backgroundColor: "white",
    borderBottomColor: "#E2E2E2",
    borderBottomWidth: 1,
    height: 50,
    color: "#000",
    paddingLeft: 0,
  },
  buttonMain: {
    backgroundColor: "#FFAD37",
    borderColor: "transparent",
    borderRadius: 5,
    color: "#fff",
    display: "flex",
    fontWeight: "500",
    fontSize: 13,
    height: 46,
    justifyContent: "center",
    marginTop: 30,
    width: "100%",
  },
  buttonText: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: "500",
    color: "#000",
    fontFamily: "poppins-semibold",
  },
  headerProgress: {
    alignSelf: "center",
    backgroundColor: "#f4f4f4",
    borderRadius: 100,
    fontSize: 12,
    color: "#000",
    padding: 15,
    fontFamily: "poppins-regular",
    overflow: "hidden",
  },
  headerProgressText: {
    backgroundColor: "#f4f4f4",
    borderWidth: 1,
    borderColor: "#f4f4f4",
    borderRadius: 10,
    overflow: "hidden",
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
    color: "#000",
  },
  backButtonIcon: {
    height: 20,
    width: 20,
  },
  topBar: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 20,
    marginBottom: 20,
    marginTop: 10,
  },
  subBar: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 30,
    marginTop: 10,
  },
  startButtonText: {
    color: "#004A8F",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "right",
  },
  startButtonRecover: {
    color: "black",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "center",
  },
  especialidadesList: {
    paddingBottom: 100,
    paddingTop: 0,
    marginTop: -10,
  },
  especialidadesListSpec: {
    backgroundColor: "#F8BC47",
  },
  especialidadesListPart: {
    fontFamily: "poppins-semibold",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderColor: "white",
    color: "#222",
    top: 5,
  },
  especialidadesListItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 80,
  },
  especialidadesListItemAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  especialidadesListPart: {
    fontFamily: "poppins-regular",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    color: "#666",
    marginRight: 10,
  },
});

export default IndexEspecialidades;
